import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

function Telemarketing() {
    const baseUrl='https://websfym.wwf.cl/api/telemarketing/';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [telemarketingSeleccionado, setTelemarketingSeleccionado]=useState({
        tel_id: '',
        tel_nombre: '',
        tel_apellido: '',
        tel_rut: '',
        tel_codigo: '',
        tel_status: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setTelemarketingSeleccionado((prevState)=>({
        ...prevState,
        [name]: value
        }))
        console.log(telemarketingSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.tel_id;
        setTelemarketingSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        console.log(estatuslist)
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("tel_nombre", telemarketingSeleccionado.tel_nombre);
        f.append("tel_apellido", telemarketingSeleccionado.tel_apellido);
        f.append("tel_rut", telemarketingSeleccionado.tel_rut);
        f.append("tel_codigo", telemarketingSeleccionado.tel_codigo);
        f.append("tel_status", telemarketingSeleccionado.tel_status);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
        setData(data.concat(response.data));
        
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("tel_nombre", telemarketingSeleccionado.tel_nombre);
        f.append("tel_apellido", telemarketingSeleccionado.tel_apellido);
        f.append("tel_rut", telemarketingSeleccionado.tel_rut);
        f.append("tel_codigo", telemarketingSeleccionado.tel_codigo);
        f.append("tel_status", telemarketingSeleccionado.tel_status);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {tel_id: telemarketingSeleccionado.tel_status}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(telemarketing=>{
            if(telemarketing.tel_id===telemarketingSeleccionado.tel_id){
                telemarketing.tel_nombre=telemarketingSeleccionado.tel_nombre;
                telemarketing.tel_apellido=telemarketingSeleccionado.tel_apellido;
                telemarketing.tel_rut=telemarketingSeleccionado.tel_rut;
                telemarketing.tel_codigo=telemarketingSeleccionado.tel_codigo;
                telemarketing.tel_status=telemarketingSeleccionado.tel_status;
            }
        });
        setData(dataNueva);
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    
    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {tel_id: telemarketingSeleccionado.tel_id}})
        .then(response=>{
        setData(data.filter(telemarketing=>telemarketing.tel_id!==telemarketingSeleccionado.tel_id));
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(tel_id,check)=>{
        var f = new FormData();
        f.append("tel_id", tel_id);
        f.append("tel_status", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {tel_id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`tel_status-${tel_id}`
            setTelemarketingSeleccionado({
                ...telemarketingSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarTelemarketing=(telemarketing, caso)=>{
        setTelemarketingSeleccionado(telemarketing);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEliminar()
    }

    useEffect(()=>{
        peticionGet();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>telemarketing</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">telemarketing</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista de usuarios */}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre y Apellido</th>
                                                <th>Rut</th>
                                                <th>Codigo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(telemarketing=>(
                                                <tr key={telemarketing.tel_id}>
                                                    <td>{telemarketing.tel_id}</td>
                                                    <td>{telemarketing.tel_nombre} {telemarketing.tel_apellido}</td>
                                                    <td>{telemarketing.tel_rut}</td>
                                                    <td>{telemarketing.tel_codigo}</td>
                                                    
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={telemarketing.tel_status==='true' ? true : false} className="custom-control-input" id={`tel_status-${telemarketing.tel_id}`} name={`tel_status-${telemarketing.tel_id}`} data-id={telemarketing.tel_id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`tel_status-${telemarketing.tel_id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarTelemarketing(telemarketing, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarTelemarketing(telemarketing, "Eliminar")}>Eliminar</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="tel_nombre" value={telemarketingSeleccionado ? telemarketingSeleccionado.tel_nombre: ''} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input className="form-control" type="text" name="tel_apellido" value={telemarketingSeleccionado ? telemarketingSeleccionado.tel_apellido: ''} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Rut</label>
                                                <input className="form-control" type="text" name="tel_rut" value={telemarketingSeleccionado ? telemarketingSeleccionado.tel_rut: ''} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Codigo</label>
                                                <input className="form-control" type="text" name="tel_codigo" value={telemarketingSeleccionado ? telemarketingSeleccionado.tel_codigo: ''} onChange={handleChange} />
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="tel_nombre" onChange={handleChange} value={telemarketingSeleccionado && telemarketingSeleccionado.tel_nombre}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input type="text" className="form-control" name="tel_apellido" onChange={handleChange} value={telemarketingSeleccionado && telemarketingSeleccionado.tel_apellido}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Rut</label>
                                                <input type="text" className="form-control" name="tel_rut" onChange={handleChange} value={telemarketingSeleccionado && telemarketingSeleccionado.tel_rut}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Codigo</label>
                                                <input type="text" className="form-control" name="tel_codigo" onChange={handleChange} value={telemarketingSeleccionado && telemarketingSeleccionado.tel_codigo}/>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar el telemarketing {telemarketingSeleccionado && telemarketingSeleccionado.tel_codigo}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Telemarketing;