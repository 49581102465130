import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import ModalDialog from 'react-bootstrap/ModalDialog';
// import ModalHeader from 'react-bootstrap/ModalHeader'
// import ModalTitle from 'react-bootstrap/ModalTitle'
// import ModalBody from 'react-bootstrap/ModalBody'
// import ModalFooter from 'react-bootstrap/ModalFooter'
import Table from 'react-bootstrap/Table'

function Formularios() {
    const baseUrl='https://websfym.wwf.cl/api/formularios/';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [formularioSeleccionado, setFormularioSeleccionado]=useState({
        form_id: '',
        form_nombre: '',
        form_tipo: '',
   
        form_campos: '',
        form_parametros: '',
        form_tipo_aporte: '',
        form_botones: '',
        form_logo_payku: '',
        form_leyenda_payku: '',
        form_text_boton: '',
        
        form_titulo:'',

        nombre:'',
        apellido:'',
        email:'',
        telefono:'',
        rut:'',
        direccion:'',
        mensaje:'',
        informacion:'',
        comre:'',
        fecnac:'',

        form_bm_monto1:'',form_bm_nombre1:'',form_bm_url1:'',form_bm_estatus1:'',
        form_bm_monto2:'',form_bm_nombre2:'',form_bm_url2:'',form_bm_estatus2:'',
        form_bm_monto3:'',form_bm_nombre3:'',form_bm_url3:'',form_bm_estatus3:'',
        form_bm_monto4:'',form_bm_nombre4:'',form_bm_url4:'',form_bm_estatus4:'',
        form_bm_monto5:'',form_bm_nombre5:'',form_bm_url5:'',form_bm_estatus5:'',
        form_bm_monto6:'',form_bm_nombre6:'',form_bm_url6:'',form_bm_estatus6:'',

        form_ba_monto1:'',form_ba_nombre1:'',form_ba_url1:'',form_ba_estatus1:'',
        form_ba_monto2:'',form_ba_nombre2:'',form_ba_url2:'',form_ba_estatus2:'',
        form_ba_monto3:'',form_ba_nombre3:'',form_ba_url3:'',form_ba_estatus3:'',
        form_ba_monto4:'',form_ba_nombre4:'',form_ba_url4:'',form_ba_estatus4:'',
        form_ba_monto5:'',form_ba_nombre5:'',form_ba_url5:'',form_ba_estatus5:'',
        form_ba_monto6:'',form_ba_nombre6:'',form_ba_url6:'',form_ba_estatus6:'',

        form_bu_monto1:'',form_bu_nombre1:'',form_bu_url1:'',form_bu_estatus1:'',
        form_bu_monto2:'',form_bu_nombre2:'',form_bu_url2:'',form_bu_estatus2:'',
        form_bu_monto3:'',form_bu_nombre3:'',form_bu_url3:'',form_bu_estatus3:'',
        form_bu_monto4:'',form_bu_nombre4:'',form_bu_url4:'',form_bu_estatus4:'',
        form_bu_monto5:'',form_bu_nombre5:'',form_bu_url5:'',form_bu_estatus5:'',
        form_bu_monto6:'',form_bu_nombre6:'',form_bu_url6:'',form_bu_estatus6:'',

        form_mensual:'',
        form_unica:'',
        form_anual:'',
        form_marcar_m:'',
        form_marcar_a:'',
        form_marcar_u:'',
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setFormularioSeleccionado((prevState)=>({
        ...prevState,
        [name]: value
        }))
        console.log(formularioSeleccionado);
    }

    // const [checked1, setChecked1] = useState('');
    

    // const handleChangeCheck1 = () => {
    //     setChecked1(!checked1);
    //     console.log(checked1)
    // };
    



    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setFormularioSeleccionado({
            ...formularioSeleccionado,
            [name]: files[0],
        })
        // console.log(files[0].type);
        // console.log(files[0].name);
        // console.log(files[0].size);
        console.log(formularioSeleccionado);
    }


    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }


    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        console.log(response);
        setData(response.data);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("form_nombre", formularioSeleccionado.form_nombre);
        f.append("form_tipo", formularioSeleccionado.form_tipo);
        // f.append("form_gtag", formularioSeleccionado.form_gtag);
        f.append("form_campos", formularioSeleccionado.form_campos);
        f.append("form_parametros", formularioSeleccionado.form_parametros);
        f.append("form_tipo_aporte", formularioSeleccionado.form_tipo_aporte);
        f.append("form_botones", formularioSeleccionado.form_botones);
        f.append("form_logo_payku", formularioSeleccionado.form_logo_payku);
        f.append("form_leyenda_payku", formularioSeleccionado.form_leyenda_payku);
        f.append("form_text_boton", formularioSeleccionado.form_text_boton);
        
        f.append("form_titulo", formularioSeleccionado.form_titulo);
        

        f.append("form_bm_monto1", formularioSeleccionado.form_bm_monto1);
        f.append("form_bm_nombre1", formularioSeleccionado.form_bm_nombre1);
        f.append("form_bm_url1", formularioSeleccionado.form_bm_url1);
        f.append("form_bm_estatus1", formularioSeleccionado.form_bm_estatus1);

        f.append("form_bm_monto2", formularioSeleccionado.form_bm_monto2);
        f.append("form_bm_nombre2", formularioSeleccionado.form_bm_nombre2);
        f.append("form_bm_url2", formularioSeleccionado.form_bm_url2);
        f.append("form_bm_estatus2", formularioSeleccionado.form_bm_estatus2);

        f.append("form_bm_monto3", formularioSeleccionado.form_bm_monto3);
        f.append("form_bm_nombre3", formularioSeleccionado.form_bm_nombre3);
        f.append("form_bm_url3", formularioSeleccionado.form_bm_url3);
        f.append("form_bm_estatus3", formularioSeleccionado.form_bm_estatus3);

        f.append("form_bm_monto4", formularioSeleccionado.form_bm_monto4);
        f.append("form_bm_nombre4", formularioSeleccionado.form_bm_nombre4);
        f.append("form_bm_url4", formularioSeleccionado.form_bm_url4);
        f.append("form_bm_estatus4", formularioSeleccionado.form_bm_estatus4);

        f.append("form_bm_monto5", formularioSeleccionado.form_bm_monto5);
        f.append("form_bm_nombre5", formularioSeleccionado.form_bm_nombre5);
        f.append("form_bm_url5", formularioSeleccionado.form_bm_url5);
        f.append("form_bm_estatus5", formularioSeleccionado.form_bm_estatus5);

        f.append("form_bm_monto6", formularioSeleccionado.form_bm_monto6);
        f.append("form_bm_nombre6", formularioSeleccionado.form_bm_nombre6);
        f.append("form_bm_url6", formularioSeleccionado.form_bm_url6);
        f.append("form_bm_estatus6", formularioSeleccionado.form_bm_estatus6);


        f.append("form_ba_monto1", formularioSeleccionado.form_ba_monto1);
        f.append("form_ba_nombre1", formularioSeleccionado.form_ba_nombre1);
        f.append("form_ba_url1", formularioSeleccionado.form_ba_url1);
        f.append("form_ba_estatus1", formularioSeleccionado.form_ba_estatus1);

        f.append("form_ba_monto2", formularioSeleccionado.form_ba_monto2);
        f.append("form_ba_nombre2", formularioSeleccionado.form_ba_nombre2);
        f.append("form_ba_url2", formularioSeleccionado.form_ba_url2);
        f.append("form_ba_estatus2", formularioSeleccionado.form_ba_estatus2);

        f.append("form_ba_monto3", formularioSeleccionado.form_ba_monto3);
        f.append("form_ba_nombre3", formularioSeleccionado.form_ba_nombre3);
        f.append("form_ba_url3", formularioSeleccionado.form_ba_url3);
        f.append("form_ba_estatus3", formularioSeleccionado.form_ba_estatus3);

        f.append("form_ba_monto4", formularioSeleccionado.form_ba_monto4);
        f.append("form_ba_nombre4", formularioSeleccionado.form_ba_nombre4);
        f.append("form_ba_url4", formularioSeleccionado.form_ba_url4);
        f.append("form_ba_estatus4", formularioSeleccionado.form_ba_estatus4);


        f.append("form_ba_monto5", formularioSeleccionado.form_ba_monto5);
        f.append("form_ba_nombre5", formularioSeleccionado.form_ba_nombre5);
        f.append("form_ba_url5", formularioSeleccionado.form_ba_url5);
        f.append("form_ba_estatus5", formularioSeleccionado.form_ba_estatus5);

        f.append("form_ba_monto6", formularioSeleccionado.form_ba_monto6);
        f.append("form_ba_nombre6", formularioSeleccionado.form_ba_nombre6);
        f.append("form_ba_url6", formularioSeleccionado.form_ba_url6);
        f.append("form_ba_estatus6", formularioSeleccionado.form_ba_estatus6);

        f.append("form_bu_monto1", formularioSeleccionado.form_bu_monto1);
        f.append("form_bu_nombre1", formularioSeleccionado.form_bu_nombre1);
        f.append("form_bu_url1", formularioSeleccionado.form_bu_url1);
        f.append("form_bu_estatus1", formularioSeleccionado.form_bu_estatus1);

        f.append("form_bu_monto1", formularioSeleccionado.form_bu_monto1);
        f.append("form_bu_nombre1", formularioSeleccionado.form_bu_nombre1);
        f.append("form_bu_url1", formularioSeleccionado.form_bu_url1);
        f.append("form_bu_estatus1", formularioSeleccionado.form_bu_estatus1);

        f.append("form_bu_monto2", formularioSeleccionado.form_bu_monto2);
        f.append("form_bu_nombre2", formularioSeleccionado.form_bu_nombre2);
        f.append("form_bu_url2", formularioSeleccionado.form_bu_url2);
        f.append("form_bu_estatus2", formularioSeleccionado.form_bu_estatus2);

        f.append("form_bu_monto3", formularioSeleccionado.form_bu_monto3);
        f.append("form_bu_nombre3", formularioSeleccionado.form_bu_nombre1);
        f.append("form_bu_url3", formularioSeleccionado.form_bu_url3);
        f.append("form_bu_estatus3", formularioSeleccionado.form_bu_estatus3);

        f.append("form_bu_monto4", formularioSeleccionado.form_bu_monto4);
        f.append("form_bu_nombre4", formularioSeleccionado.form_bu_nombre4);
        f.append("form_bu_url4", formularioSeleccionado.form_bu_url4);
        f.append("form_bu_estatus4", formularioSeleccionado.form_bu_estatus4);

        f.append("form_bu_monto5", formularioSeleccionado.form_bu_monto5);
        f.append("form_bu_nombre5", formularioSeleccionado.form_bu_nombre5);
        f.append("form_bu_url5", formularioSeleccionado.form_bu_url5);
        f.append("form_bu_estatus5", formularioSeleccionado.form_bu_estatus5);

        f.append("form_bu_monto6", formularioSeleccionado.form_bu_monto6);
        f.append("form_bu_nombre6", formularioSeleccionado.form_bu_nombre6);
        f.append("form_bu_url6", formularioSeleccionado.form_bu_url6);
        f.append("form_bu_estatus6", formularioSeleccionado.form_bu_estatus6);

        f.append("nombre", formularioSeleccionado.nombre);
        f.append("apellido", formularioSeleccionado.apellido);
        f.append("email", formularioSeleccionado.email);
        f.append("telefono", formularioSeleccionado.telefono);
        f.append("rut", formularioSeleccionado.rut);
        f.append("direccion", formularioSeleccionado.direccion);
        f.append("mensaje", formularioSeleccionado.mensaje);
        f.append("informacion", formularioSeleccionado.informacion);
        f.append("comre", formularioSeleccionado.comre);
        f.append("fecnac", formularioSeleccionado.fecnac);

        f.append("form_mensual", formularioSeleccionado.form_mensual);
        f.append("form_unica", formularioSeleccionado.form_unica);
        f.append("form_anual", formularioSeleccionado.form_anual);
        f.append("form_marcar_m", formularioSeleccionado.form_marcar_m);
        f.append("form_marcar_a", formularioSeleccionado.form_marcar_a);
        f.append("form_marcar_u", formularioSeleccionado.form_marcar_u);

        f.append("METHOD", "POST");
         console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
        setData(data.concat(response.data));
        peticionGet();
        // console.log(response);
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("form_nombre", formularioSeleccionado.form_nombre);
        f.append("form_tipo", formularioSeleccionado.form_tipo);
        // f.append("form_gtag", formularioSeleccionado.form_gtag);
        f.append("form_campos", formularioSeleccionado.form_campos);
        f.append("form_parametros", formularioSeleccionado.form_parametros);
        f.append("form_tipo_aporte", formularioSeleccionado.form_tipo_aporte);
        f.append("form_botones", formularioSeleccionado.form_botones);
        f.append("form_logo_payku", formularioSeleccionado.form_logo_payku);
        f.append("form_leyenda_payku", formularioSeleccionado.form_leyenda_payku);
        f.append("form_text_boton", formularioSeleccionado.form_text_boton);
        
        f.append("form_titulo", formularioSeleccionado.form_titulo);
        

        f.append("form_bm_monto1", formularioSeleccionado.form_bm_monto1);
        f.append("form_bm_nombre1", formularioSeleccionado.form_bm_nombre1);
        f.append("form_bm_url1", formularioSeleccionado.form_bm_url1);
        f.append("form_bm_estatus1", formularioSeleccionado.form_bm_estatus1);

        f.append("form_bm_monto2", formularioSeleccionado.form_bm_monto2);
        f.append("form_bm_nombre2", formularioSeleccionado.form_bm_nombre2);
        f.append("form_bm_url2", formularioSeleccionado.form_bm_url2);
        f.append("form_bm_estatus2", formularioSeleccionado.form_bm_estatus2);

        f.append("form_bm_monto3", formularioSeleccionado.form_bm_monto3);
        f.append("form_bm_nombre3", formularioSeleccionado.form_bm_nombre3);
        f.append("form_bm_url3", formularioSeleccionado.form_bm_url3);
        f.append("form_bm_estatus3", formularioSeleccionado.form_bm_estatus3);

        f.append("form_bm_monto4", formularioSeleccionado.form_bm_monto4);
        f.append("form_bm_nombre4", formularioSeleccionado.form_bm_nombre4);
        f.append("form_bm_url4", formularioSeleccionado.form_bm_url4);
        f.append("form_bm_estatus4", formularioSeleccionado.form_bm_estatus4);

        f.append("form_bm_monto5", formularioSeleccionado.form_bm_monto5);
        f.append("form_bm_nombre5", formularioSeleccionado.form_bm_nombre5);
        f.append("form_bm_url5", formularioSeleccionado.form_bm_url5);
        f.append("form_bm_estatus5", formularioSeleccionado.form_bm_estatus5);

        f.append("form_bm_monto6", formularioSeleccionado.form_bm_monto6);
        f.append("form_bm_nombre6", formularioSeleccionado.form_bm_nombre6);
        f.append("form_bm_url6", formularioSeleccionado.form_bm_url6);
        f.append("form_bm_estatus6", formularioSeleccionado.form_bm_estatus6);


        f.append("form_ba_monto1", formularioSeleccionado.form_ba_monto1);
        f.append("form_ba_nombre1", formularioSeleccionado.form_ba_nombre1);
        f.append("form_ba_url1", formularioSeleccionado.form_ba_url1);
        f.append("form_ba_estatus1", formularioSeleccionado.form_ba_estatus1);

        f.append("form_ba_monto2", formularioSeleccionado.form_ba_monto2);
        f.append("form_ba_nombre2", formularioSeleccionado.form_ba_nombre2);
        f.append("form_ba_url2", formularioSeleccionado.form_ba_url2);
        f.append("form_ba_estatus2", formularioSeleccionado.form_ba_estatus2);

        f.append("form_ba_monto3", formularioSeleccionado.form_ba_monto3);
        f.append("form_ba_nombre3", formularioSeleccionado.form_ba_nombre3);
        f.append("form_ba_url3", formularioSeleccionado.form_ba_url3);
        f.append("form_ba_estatus3", formularioSeleccionado.form_ba_estatus3);

        f.append("form_ba_monto4", formularioSeleccionado.form_ba_monto4);
        f.append("form_ba_nombre4", formularioSeleccionado.form_ba_nombre4);
        f.append("form_ba_url4", formularioSeleccionado.form_ba_url4);
        f.append("form_ba_estatus4", formularioSeleccionado.form_ba_estatus4);


        f.append("form_ba_monto5", formularioSeleccionado.form_ba_monto5);
        f.append("form_ba_nombre5", formularioSeleccionado.form_ba_nombre5);
        f.append("form_ba_url5", formularioSeleccionado.form_ba_url5);
        f.append("form_ba_estatus5", formularioSeleccionado.form_ba_estatus5);

        f.append("form_ba_monto6", formularioSeleccionado.form_ba_monto6);
        f.append("form_ba_nombre6", formularioSeleccionado.form_ba_nombre6);
        f.append("form_ba_url6", formularioSeleccionado.form_ba_url6);
        f.append("form_ba_estatus6", formularioSeleccionado.form_ba_estatus6);

        f.append("form_bu_monto1", formularioSeleccionado.form_bu_monto1);
        f.append("form_bu_nombre1", formularioSeleccionado.form_bu_nombre1);
        f.append("form_bu_url1", formularioSeleccionado.form_bu_url1);
        f.append("form_bu_estatus1", formularioSeleccionado.form_bu_estatus1);

        f.append("form_bu_monto1", formularioSeleccionado.form_bu_monto1);
        f.append("form_bu_nombre1", formularioSeleccionado.form_bu_nombre1);
        f.append("form_bu_url1", formularioSeleccionado.form_bu_url1);
        f.append("form_bu_estatus1", formularioSeleccionado.form_bu_estatus1);

        f.append("form_bu_monto2", formularioSeleccionado.form_bu_monto2);
        f.append("form_bu_nombre2", formularioSeleccionado.form_bu_nombre2);
        f.append("form_bu_url2", formularioSeleccionado.form_bu_url2);
        f.append("form_bu_estatus2", formularioSeleccionado.form_bu_estatus2);

        f.append("form_bu_monto3", formularioSeleccionado.form_bu_monto3);
        f.append("form_bu_nombre3", formularioSeleccionado.form_bu_nombre1);
        f.append("form_bu_url3", formularioSeleccionado.form_bu_url3);
        f.append("form_bu_estatus3", formularioSeleccionado.form_bu_estatus3);

        f.append("form_bu_monto4", formularioSeleccionado.form_bu_monto4);
        f.append("form_bu_nombre4", formularioSeleccionado.form_bu_nombre4);
        f.append("form_bu_url4", formularioSeleccionado.form_bu_url4);
        f.append("form_bu_estatus4", formularioSeleccionado.form_bu_estatus4);

        f.append("form_bu_monto5", formularioSeleccionado.form_bu_monto5);
        f.append("form_bu_nombre5", formularioSeleccionado.form_bu_nombre5);
        f.append("form_bu_url5", formularioSeleccionado.form_bu_url5);
        f.append("form_bu_estatus5", formularioSeleccionado.form_bu_estatus5);

        f.append("form_bu_monto6", formularioSeleccionado.form_bu_monto6);
        f.append("form_bu_nombre6", formularioSeleccionado.form_bu_nombre6);
        f.append("form_bu_url6", formularioSeleccionado.form_bu_url6);
        f.append("form_bu_estatus6", formularioSeleccionado.form_bu_estatus6);

        f.append("nombre", formularioSeleccionado.nombre);
        f.append("apellido", formularioSeleccionado.apellido);
        f.append("email", formularioSeleccionado.email);
        f.append("telefono", formularioSeleccionado.telefono);
        f.append("rut", formularioSeleccionado.rut);
        f.append("direccion", formularioSeleccionado.direccion);
        f.append("mensaje", formularioSeleccionado.mensaje);
        f.append("informacion", formularioSeleccionado.informacion);
        f.append("comre", formularioSeleccionado.comre);
        f.append("fecnac", formularioSeleccionado.fecnac);

        f.append("form_mensual", formularioSeleccionado.form_mensual);
        f.append("form_unica", formularioSeleccionado.form_unica);
        f.append("form_anual", formularioSeleccionado.form_anual);
        f.append("form_marcar_m", formularioSeleccionado.form_marcar_m);
        f.append("form_marcar_a", formularioSeleccionado.form_marcar_a);
        f.append("form_marcar_u", formularioSeleccionado.form_marcar_u);

        f.append("METHOD", "PUT");
        console.log(f);
        await axios.post(baseUrl, f, {params: {form_id: formularioSeleccionado.form_id}})
        .then(response=>{
            // console.log(response);
        var dataNueva= data;
        dataNueva.map(formulario=>{
            if(formulario.form_id===formularioSeleccionado.form_id){
                formulario.form_nombre=formularioSeleccionado.form_nombre;
                formulario.form_tipo=formularioSeleccionado.form_tipo;
                // formulario.form_gtag=formularioSeleccionado.form_gtag;
                formulario.form_campos=formularioSeleccionado.form_campos;
                formulario.form_parametros=formularioSeleccionado.form_parametros;
                formulario.form_tipo_aporte=formularioSeleccionado.form_tipo_aporte;
                formulario.form_botones=formularioSeleccionado.form_botones;
                formulario.form_titulo=formularioSeleccionado.form_titulo;
                
            }
        });
        setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {form_id: formularioSeleccionado.form_id}})
        .then(response=>{
        setData(data.filter(formulario=>formulario.form_id!==formularioSeleccionado.form_id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(form_id,check)=>{
        var f = new FormData();
        f.append("form_id", form_id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {form_id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${form_id}`
            setFormularioSeleccionado({
                ...formularioSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }
    
    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {form_id: formularioSeleccionado.form_id,nombre: formularioSeleccionado.form_nombre}})
        .then(response=>{
            // console.log(response);
        setData(data.filter(formulario=>formulario.form_id!==formularioSeleccionado.form_id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarFormulario=(formulario, caso)=>{
        setFormularioSeleccionado(formulario);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setFormularioSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        // console.log(!value);
        console.log(estatuslist)
    }

    const verCampana=(formulario,form_tipo, form_id)=>{
       
         var ruta='';
        if(form_tipo==1) {
           ruta='dona?form=';
        }else if(form_tipo==2){
            ruta='adopta?form=';
        }else{
            ruta='haztesocio?form=';
        }
               
        window.open('https://websfym.wwf.cl/formularios/'+ruta+form_id, '_blank')
    }

    useEffect(()=>{
        peticionGet();
    },[])


    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Formularios</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">formularios</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                         <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista de usuarios */}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>
                                                <th>Tipo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(formulario=>(
                                                <tr key={formulario.form_id}>
                                                    <td>{formulario.form_id}</td>
                                                    <td>{formulario.form_nombre} </td>
                                                    <td>
                                                        {(() => {
                                                            switch (formulario.form_tipo) {
                                                            case "1":   return "DONA";
                                                            case "2": return "ADOPTA";
                                                            case "3":  return "HAZTE SOCIO";
                                                            }
                                                        })()}
                                                    </td>
                                                     
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={formulario.form_estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${formulario.form_id}`} name={`estatus-${formulario.form_id}`} data-id={formulario.form_id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${formulario.form_id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarFormulario(formulario, "Editar")}>Editar</button> {"  "} 
                                                        <button className="btn btn-success" onClick={() => seleccionarFormulario(formulario, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarFormulario(formulario, "Eliminar")}>Eliminar</button>{"  "}
                                                        <button className="btn btn-info" onClick={()=>verCampana(formulario, formulario.form_tipo,formulario.form_id)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal size="lg" isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="form_nombre"  onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="form_titulo"  onChange={handleChange}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Tipo de Formulario</label>
                                                        <select className="form-control" type="text" name="form_tipo" onChange={handleChange}>
                                                            <option>opción</option>
                                                            <option value="1">Dona</option>
                                                            <option value="2">Adopción</option>
                                                            <option value="3">Hazte socio</option>
                                                        </select>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label>gtag</label>
                                                        <input className="form-control" type="text" name="form_gtag" onChange={handleChange} />
                                                    </div> */}
                                                    <br></br>
                                                    
                                                    <label>Tipo de aporte</label>
                                                </div>
                                                <div className="col-sm-12">
                                                    <Tabs defaultActiveKey="mensual" id="uncontrolled-tab-example" className="mb-3">
                                                        <Tab eventKey="mensual" title="Mensual">
                                                            <div class="form-group">
                                                                <label>Estatus</label>
                                                                <select className="form-control" type="text" name="form_mensual" onChange={handleChange}>
                                                                    <option value="false">Deshabilitado</option>
                                                                    <option value="true">Habilitado</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Marcar por defecto</label>
                                                                <select className="form-control" type="text" name="form_marcar_m" onChange={handleChange}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">Otro</option>
                                                                </select>
                                                            </div>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>Monto</th>
                                                                    <th>Nombre</th>
                                                                    <th>Url</th>
                                                                    {/* <th>Estatus</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto1"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre1"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url1"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus1"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto2"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre2"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url2"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus2"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto3"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre3"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url3"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus3"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto4"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre4"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url4"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus4"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Otro</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto5"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre5"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url5"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus5"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>6</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_estatus6"  onChange={handleChange}/></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                        <Tab eventKey="anual" title="Anual">
                                                            <div class="form-group">
                                                                <label>Estatus</label>
                                                                <select className="form-control" type="text" name="form_anual" onChange={handleChange}>
                                                                    <option value="false">Deshabilitado</option>
                                                                    <option value="true">Habilitado</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Marcar por defecto</label>
                                                                <select className="form-control" type="text" name="form_marcar_a" onChange={handleChange}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">Otro</option>
                                                                </select>
                                                            </div>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>Monto</th>
                                                                    <th>Nombre</th>
                                                                    <th>Url</th>
                                                                    {/* <th>Estatus</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto1"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre1"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url1"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus1"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto2"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre2"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url2"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus2"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto3"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre3"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url3"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus3"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto4"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre4"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url4"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus4"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Otro</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto5"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre5"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url5"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus5"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>6</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_estatus6"  onChange={handleChange}/></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                        <Tab eventKey="unico" title="Único">
                                                            <div class="form-group">
                                                                <label>Estatus</label>
                                                                <select className="form-control" type="text" name="form_unica" onChange={handleChange}>
                                                                    <option value="false">Deshabilitado</option>
                                                                    <option value="true">Habilitado</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Marcar por defecto</label>
                                                                <select className="form-control" type="text" name="form_marcar_u" onChange={handleChange}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">Otro</option>
                                                                </select>
                                                            </div>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>Monto</th>
                                                                    <th>Nombre</th>
                                                                    <th>Url</th>
                                                                    {/* <th>Estatus</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto1"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre1"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url1"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus1"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto2"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre2"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url2"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus2"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto3"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre3"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url3"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus3"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto4"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre4"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url4"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus4"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Otro</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto5"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre5"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url5"  onChange={handleChange}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus5"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>6</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_estatus6"  onChange={handleChange}/></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                    </Tabs>
                                                    <label>Campos</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div class="form-group">
                                                        <label>Apellido</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="apellido"  name="apellido" value={formularioSeleccionado.apellido} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Nombre</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="nombre"  name="nombre" value={formularioSeleccionado.nombre} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <select disabled className="form-control" type="text"  onChange={handleChange} id="email"  name="email" value={formularioSeleccionado.email} >
                                                            <option value="true">Si</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div class="form-group">
                                                        <label>Telefono</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="telefono"  name="telefono" value={formularioSeleccionado.telefono} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Rut</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="rut"  name="rut" value={formularioSeleccionado.rut} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Dirección</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="direccion"  name="direccion" value={formularioSeleccionado.direccion} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div class="form-group">
                                                        <label>mensaje</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="mensaje"  name="mensaje" value={formularioSeleccionado.mensaje} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Información</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="informacion"  name="informacion" value={formularioSeleccionado.informacion} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group">
                                                        <label>Comuna / Región</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="comre"  name="comre" value={formularioSeleccionado.comre} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                        </select>
                                                    </div>
                                                   
                                                </div>
                                                <div className="col-sm-12">
                                                    <div class="form-group">
                                                        <label>Fecha de Nacimiento</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="fecnac"  name="fecnac" value={formularioSeleccionado.fecnac} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                        </select>
                                                    </div>                                                    
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Texto Boton</label>
                                                        <input className="form-control" type="text" name="form_text_boton"  onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Imagen leyenda</label>
                                                        <input className="form-control" type="text" name="form_logo_payku"  onChange={handleChange}/>
                                                        {/* <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="form_logo_payku" id="form_logo_payku" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="form_logo_payku">{formularioSeleccionado.form_logo_payku.name ? formularioSeleccionado.form_logo_payku.name : "Cargar Imagen"}</label>
                                                        </div> */}
                                                    </div>
                                                   
                                                    <div className="form-group">
                                                        <label>Texto Leyenda</label>
                                                        <input className="form-control" type="text" name="form_leyenda_payku" onChange={handleChange} />
                                                    </div>
                                                    <br></br>
                                                  
                                                </div>
                                            </div>    
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal size="lg" isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>                                            
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input type="text" className="form-control" name="form_nombre" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_nombre}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input type="text" className="form-control" name="form_titulo" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_titulo}/>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Tipo de Formulario</label>
                                                        <select className="form-control" type="text" name="form_tipo" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_tipo}>
                                                            <option>opción</option>
                                                            <option value="1">Dona</option>
                                                            <option value="2">Adopción</option>
                                                            <option value="3">Hazte socio</option>
                                                        </select>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label>gtag</label>
                                                        <input className="form-control" type="text" name="form_gtag" onChange={handleChange} />
                                                    </div> */}
                                                    <br></br>
                                                    
                                                    <label>Tipo de aporte</label>
                                                </div>
                                                <div className="col-sm-12">
                                                    <Tabs defaultActiveKey="mensual" id="uncontrolled-tab-example" className="mb-3">
                                                        <Tab eventKey="mensual" title="Mensual">
                                                            <div class="form-group">
                                                                <label>Estatus</label>
                                                                <select className="form-control" type="text" name="form_mensual" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_mensual}>
                                                                    <option value="false">Deshabilitado</option>
                                                                    <option value="true">Habilitado</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Marcar por defecto</label>
                                                                <select className="form-control" type="text" name="form_marcar_m" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_marcar_m}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">Otro</option>
                                                                </select>
                                                            </div>

                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>Monto</th>
                                                                    <th>Nombre</th>
                                                                    <th>Url</th>
                                                                    {/* <th>Estatus</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_monto1}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_nombre1}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_url1}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus1"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_monto2}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_nombre2}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_url2}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus2"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_monto3}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_nombre3}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_url3}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus3"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_monto4}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_nombre4}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_url4}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus4"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Otro</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_monto5}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_nombre5}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bm_url5}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bm_estatus5"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>6</td>
                                                                        <td><input className="form-control" type="text" name="form_bm_monto6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_nombre6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_url6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bm_estatus6"  onChange={handleChange}/></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                        <Tab eventKey="anual" title="Anual">
                                                            <div class="form-group">
                                                                <label>Estatus</label>
                                                                <select className="form-control" type="text" name="form_anual" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_anual}>
                                                                    <option value="false">Deshabilitado</option>
                                                                    <option value="true">Habilitado</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Marcar por defecto</label>
                                                                <select className="form-control" type="text" name="form_marcar_a" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_marcar_a}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">Otro</option>
                                                                </select>
                                                            </div>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>Monto</th>
                                                                    <th>Nombre</th>
                                                                    <th>Url</th>
                                                                    {/* <th>Estatus</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_monto1}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_nombre1}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_url1}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus1"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_monto2}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_nombre2}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_url2}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus2"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_monto3}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_nombre3}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_url3}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus3"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_monto4}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_nombre4}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_url4}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus4"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Otro</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_monto5}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_nombre5}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_ba_url5}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_ba_estatus5"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>6</td>
                                                                        <td><input className="form-control" type="text" name="form_ba_monto6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_nombre6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_url6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_ba_estatus6"  onChange={handleChange}/></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                        <Tab eventKey="unico" title="Único">
                                                            <div class="form-group">
                                                                <label>Estatus</label>
                                                                <select className="form-control" type="text" name="form_unica"  id="form_unica"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_unica}>
                                                                    <option value="false">Deshabilitado</option>
                                                                    <option value="true">Habilitado</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Marcar por defecto</label>
                                                                <select className="form-control" type="text" name="form_marcar_u" id="form_marcar_u"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_marcar_u}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">Otro</option>
                                                                </select>
                                                            </div>
                                                            <Table striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>Monto</th>
                                                                    <th>Nombre</th>
                                                                    <th>Url</th>
                                                                    {/* <th>Estatus</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto1"  onChange={handleChange}  value={formularioSeleccionado && formularioSeleccionado.form_bu_monto1}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_nombre1}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url1"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_url1}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus1"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_monto2}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_nombre2}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url2"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_url2}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus2"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_monto3}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_nombre3}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url3"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_url3}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus3"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_monto4}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_nombre4}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url4"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_url4}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus4"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Otro</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_monto5}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_nombre5}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url5"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_bu_url5}/></td>
                                                                        {/* <td><input className="form-control" type="text" name="form_bu_estatus5"  onChange={handleChange}/></td> */}
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>6</td>
                                                                        <td><input className="form-control" type="text" name="form_bu_monto6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_nombre6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_url6"  onChange={handleChange}/></td>
                                                                        <td><input className="form-control" type="text" name="form_bu_estatus6"  onChange={handleChange}/></td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                    </Tabs>
                                                    <label>Campos</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div class="form-group">
                                                        <label>Nombre</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="nombre"  name="nombre" value={formularioSeleccionado && formularioSeleccionado.nombre} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Apellido</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="apellido"  name="apellido" value={formularioSeleccionado && formularioSeleccionado.apellido} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <select disabled className="form-control" type="text"  onChange={handleChange} id="email"  name="email" value={formularioSeleccionado && formularioSeleccionado.email} >
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                   
                                                </div>
                                                <div className="col-sm-4">
                                                    <div class="form-group">
                                                        <label>Telefono</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="telefono"  name="telefono" value={formularioSeleccionado && formularioSeleccionado.telefono} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Rut</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="rut"  name="rut" value={formularioSeleccionado && formularioSeleccionado.rut} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Dirección</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="direccion"  name="direccion" value={formularioSeleccionado && formularioSeleccionado.direccion} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
  
                                                </div>
                                                <div className="col-sm-4">

                                                    <div class="form-group">
                                                        <label>Mensaje</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="mensaje"  name="mensaje" value={formularioSeleccionado && formularioSeleccionado.mensaje} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Información</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="informacion"  name="informacion" value={formularioSeleccionado && formularioSeleccionado.informacion} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Comuna / Región</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="comre"  name="comre" value={formularioSeleccionado && formularioSeleccionado.comre} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-sm-12">
                                                  
                                                    <div class="form-group">
                                                        <label>Fecha de Nacimiento</label>
                                                        <select className="form-control" type="text"  onChange={handleChange} id="fecnac"  name="fecnac" value={formularioSeleccionado && formularioSeleccionado.fecnac} >
                                                            <option value="false">No</option>
                                                            <option value="true">Si</option>
                                                            
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Texto Boton</label>
                                                        <input className="form-control" type="text" name="form_text_boton"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_text_boton}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Imagen leyenda</label>
                                                        <input className="form-control" type="text" name="form_logo_payku"  onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_logo_payku}/>
                                                        {/* <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="form_logo_payku" id="form_logo_payku" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="form_logo_payku">{formularioSeleccionado.form_logo_payku.name ? formularioSeleccionado.form_logo_payku.name : "Cargar Imagen"}</label>
                                                        </div> */}
                                                    </div>
                                                   
                                                    <div className="form-group">
                                                        <label>Texto Leyenda</label>
                                                        <input className="form-control" type="text" name="form_leyenda_payku" onChange={handleChange} value={formularioSeleccionado && formularioSeleccionado.form_leyenda_payku}/>
                                                    </div>
                                                    <br></br>
                                                  
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar  {formularioSeleccionado && formularioSeleccionado.form_nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {formularioSeleccionado && formularioSeleccionado.form_nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                            
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Formularios;