import React, { useState, useEffect } from "react";
function Home() {

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Inicio</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">Inicio</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                  
                <div className="card-header">
                    <h3 className="card-title">Bienvenido</h3>
                    <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus" /></button>
                    <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i className="fas fa-times" /></button>
                    </div>
                </div>
                <div className="card-body">
                    Bienvenido al administrador de los sitios web Adopta, Dona y Hazte Socio de WWF...
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                    WWF
                </div>
                {/* /.card-footer*/}
                

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
