import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import ReactExport from "react-export-excel";

function Socios() {
    const baseUrl='https://websfym.wwf.cl/api/socios/';
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [socioSeleccionado, setSocioSeleccionado]=useState({
        soc_id: '',
        soc_nombres: '',
        soc_apellidos: '',
        soc_status: '',
        soc_telefono: '',
        soc_rut: '',
        soc_fecharegistro: '',
        soc_mail: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setSocioSeleccionado((prevState)=>({
        ...prevState,
        [name]: value
        }))
        console.log(socioSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }


    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        console.log(response);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("soc_nombres", socioSeleccionado.soc_nombres);
        f.append("soc_apellidos", socioSeleccionado.soc_apellidos);
        f.append("soc_status", socioSeleccionado.soc_status);
        f.append("soc_telefono", socioSeleccionado.soc_telefono);
        f.append("soc_rut", socioSeleccionado.soc_rut);
        f.append("soc_mail", socioSeleccionado.soc_mail);
        f.append("soc_fecharegistro", socioSeleccionado.soc_fecharegistro);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
        setData(data.concat(response.data));
        
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("soc_nombres", socioSeleccionado.soc_nombres);
        f.append("soc_apellidos", socioSeleccionado.soc_apellidos);
        f.append("soc_status", socioSeleccionado.soc_status);
        f.append("soc_telefono", socioSeleccionado.soc_telefono);
        f.append("soc_rut", socioSeleccionado.soc_rut);
        f.append("soc_mail", socioSeleccionado.soc_mail);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {soc_id: socioSeleccionado.soc_id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(socio=>{
            if(socio.soc_id===socioSeleccionado.soc_id){
                socio.soc_nombres=socioSeleccionado.soc_nombres;
                socio.soc_apellidos=socioSeleccionado.soc_apellidos;
                socio.soc_mail=socioSeleccionado.soc_mail;
                socio.soc_telefono=socioSeleccionado.soc_telefono;
                socio.soc_rut=socioSeleccionado.soc_rut;
                socio.soc_status=socioSeleccionado.soc_status;
            }
        });
        setData(dataNueva);
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {soc_id: socioSeleccionado.soc_id}})
        .then(response=>{
        setData(data.filter(socio=>socio.soc_id!==socioSeleccionado.soc_id));
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarSocio=(socio, caso)=>{
        setSocioSeleccionado(socio);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEliminar()
    }

    useEffect(()=>{
        peticionGet();
    },[])

    const dataSet1 = [
        {
            name: "Johson",
            amount: 30000,
            sex: 'M',
            is_married: true
        },
        {
            name: "Monika",
            amount: 355000,
            sex: 'F',
            is_married: false
        },
        {
            name: "John",
            amount: 250000,
            sex: 'M',
            is_married: false
        },
        {
            name: "Josef",
            amount: 450500,
            sex: 'M',
            is_married: true
        }
    ];
    
    const dataSet2 = [
        {
            name: "Johnson",
            total: 25,
            remainig: 16
        },
        {
            name: "Josef",
            total: 25,
            remainig: 7
        }
    ];
    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Socios</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Socios</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    {/* <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div> */}
                                    {/* <ExcelFile element={<button>Exportar</button>} file="socios">
                                        <ExcelSheet data={data} name="socios">
                                        <ExcelColumn label="Nombres" value="soc_nombres"/>
                                        </ExcelSheet>
                                    </ExcelFile> */}

                                    <ExcelFile element={<button className="btn btn-default">Exportar</button>} file="socios">
                                        <ExcelSheet data={data} name="socios">
                                            <ExcelColumn label="Mail" value="soc_mail"/>
                                            <ExcelColumn label="Telefono" value="soc_telefono"/>
                                            <ExcelColumn label="Nombres" value="soc_nombres"/>
                                            <ExcelColumn label="Apellidos" value="soc_apellidos"/>
                                            <ExcelColumn label="Direccion" value="soc_direccion"/>
                                            <ExcelColumn label="Region" value="socrg_id"/>
                                            <ExcelColumn label="Comuna" value="soccom_id"/>
                                            <ExcelColumn label="Fecha de nacimiento" value="soc_fn"/>
                                            <ExcelColumn label="Rut" value="soc_rut"/>
                                            <ExcelColumn label="Fecha de membresia" value="soc_fm"/>
                                            <ExcelColumn label="Monto" value="soc_monto"/>
                                            <ExcelColumn label="Freciencia" value="soc_frecuencia"/>
                                            <ExcelColumn label="Desea recibir información" value="soc_info"/>
                                            <ExcelColumn label="Campaña" value="id_campana"/>
                                            <ExcelColumn label="Telemarketing" value="soctel_id"/>
                                            <ExcelColumn label="Codigo de captador" value="cap_id"/>
                                            <ExcelColumn label="Agencia" value="id_agencia"/>
                                            <ExcelColumn label="Ubicación" value="soccap_ubicacion"/>
                                            <ExcelColumn label="coordenadas" value="soccap_coordenada"/>
                                            <ExcelColumn label="utm_source" value="soc_utm_source"/>
                                            <ExcelColumn label="utm_medium" value="soc_utm_medium"/>
                                            <ExcelColumn label="utm_content" value="soc_utm_content"/>
                                            <ExcelColumn label="utm_campaign" value="soc_utm_campaign"/>
                                            <ExcelColumn label="metodo de pago" value="soc_mpago"/>
                                            <ExcelColumn label="Tipo tarjeta" value="tipo_tarjeta"/>
                                            <ExcelColumn label="suscripcion payku" value="soc_susid"/>
                                            <ExcelColumn label="transaccion payku" value="soc_traid"/>
                                            <ExcelColumn label="Regalo" value="soc_regalo"/>
                                            <ExcelColumn label="Origen" value="sic_origen"/>
                                            <ExcelColumn label="Carro" value="soc_carro"/>
                                            <ExcelColumn label="Prefiere llamada" value="soc_llamar"/> 
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                                {/* /.lista de socioS */}
                                <div className="card-body">
                                    <table id="socios" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                {/* <th>#</th> */}
                                                <th>Nombre y Apellido</th>
                                                <th>Email</th>
                                                <th>Telefono</th>
                                                <th>RUT</th>
                                                <th>Fecha</th>
                                                {/* <th>Estatus</th> */}
                                                {/* <th>Acciones</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(socio=>(

                                            <tr key={socio.soc_id}>
                                                {/* <td>{socio.soc_id}</td> */}
                                                <td>{socio.soc_nombres} {socio.soc_apellidos}</td>
                                                <td>{socio.soc_mail}</td>
                                                <td>{socio.soc_telefono}</td>
                                                <td>{socio.soc_rut}</td>
                                                <td>{socio.soc_fecharegistro}</td>
                                                {/* <td>{socio.estatus}</td> */}
                                                {/* <td>
                                                <button className="btn btn-primary" onClick={()=>seleccionarSocio(socio, "Editar")}>Editar</button> {"  "} 
                                                <button className="btn btn-danger" onClick={()=>seleccionarSocio(socio, "Eliminar")}>Eliminar</button>
                                                </td> */}
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    
                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="soc_nombres" value={socioSeleccionado ? socioSeleccionado.soc_nombres: ''} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input className="form-control" type="text" name="soc_apellidos" value={socioSeleccionado ? socioSeleccionado.soc_apellidos: ''} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" type="text" name="soc_mail" value={socioSeleccionado ? socioSeleccionado.soc_mail: ''} onChange={handleChange} />
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="soc_nombres" onChange={handleChange} value={socioSeleccionado && socioSeleccionado.soc_nombres}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input type="text" className="form-control" name="soc_apellidos" onChange={handleChange} value={socioSeleccionado && socioSeleccionado.soc_apellidos}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control" name="soc_mail" onChange={handleChange} value={socioSeleccionado && socioSeleccionado.soc_mail}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Estatus</label>
                                                <input type="text" className="form-control" name="soc_status" onChange={handleChange} value={socioSeleccionado && socioSeleccionado.soc_status}/>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    {/* <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar el Usuario {socioSeleccionado && socioSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Socios;