import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

function Usuarios() {
    const baseUrl='https://websfym.wwf.cl/api/usuarios/';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [elementoSeleccionado, setElementoSeleccionado]=useState({
        id: '',
        nombre: '',
        apellido: '',
        estatus: '',
        email: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setElementoSeleccionado({
            ...elementoSeleccionado,
            [name]:value
        })
        console.log(elementoSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setElementoSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        // console.log(!value);
        console.log(estatuslist)
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
            console.log(response);
            setData(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", elementoSeleccionado.nombre);
        f.append("apellido", elementoSeleccionado.apellido);
        f.append("email", elementoSeleccionado.email);
        f.append("METHOD", "POST");
        //console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            //console.log(response);
            setData(data.concat(response.data));
            peticionGet();
            abrirCerrarModalInsertar();
        }).catch(error=>{
            console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", elementoSeleccionado.nombre);
        f.append("apellido", elementoSeleccionado.apellido);
        f.append("email", elementoSeleccionado.email);
        f.append("estatus", elementoSeleccionado.estatus);
        f.append("METHOD", "PUT");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id: elementoSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(elemento=>{
            if(elemento.id===elementoSeleccionado.id){
                elemento.nombre=elementoSeleccionado.nombre;
                elemento.apellido=elementoSeleccionado.apellido;
                elemento.email=elementoSeleccionado.email;
                elemento.estatus=elementoSeleccionado.estatus;
            }
        });
        //setData(dataNueva);
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: elementoSeleccionado.id}})
        .then(response=>{
        setData(data.filter(elemento=>elemento.id!==elementoSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }


    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setElementoSeleccionado({
                ...elementoSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarElemento=(elemento, caso)=>{
        setElementoSeleccionado(elemento);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEliminar()
    }

    useEffect(()=>{
        peticionGet();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Usuarios</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Usuarios</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista de usuarios */}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre y Apellido</th>
                                                <th>Email</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(elemento=>(
                                                <tr key={elemento.id}>
                                                    <td>{elemento.id}</td>
                                                    <td>{elemento.nombre} {elemento.apellido}</td>
                                                    <td>{elemento.email}</td>
                                                    <td>
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" checked={elemento.estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${elemento.id}`} name={`estatus-${elemento.id}`} data-id={elemento.id} onChange={handleCheck} />
                                                        <label className="custom-control-label"  htmlFor={`estatus-${elemento.id}`}></label>
                                                    </div>
                                                    </td>
                                                <td>
                                                <button className="btn btn-warning" onClick={()=>seleccionarElemento(elemento, "Editar")}>Editar</button> {"  "}
                                                <button className="btn btn-danger" onClick={()=>seleccionarElemento(elemento, "Eliminar")}>Eliminar</button>
                                                </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input className="form-control" type="text" name="apellido"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" type="text" name="email"  onChange={handleChange} />
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={elementoSeleccionado && elementoSeleccionado.nombre}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input type="text" className="form-control" name="apellido" onChange={handleChange} value={elementoSeleccionado && elementoSeleccionado.apellido}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control" name="email" onChange={handleChange} value={elementoSeleccionado && elementoSeleccionado.email}/>
                                            </div>
                                           {/*<div className="form-group">
                                                <label>Estatus</label>
                                                <input type="text" className="form-control" name="estatus" onChange={handleChange} value={elementoSeleccionado && elementoSeleccionado.estatus}/>
                                            </div> */} 
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar el Usuario {elementoSeleccionado && elementoSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Usuarios;