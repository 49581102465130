import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

function Preguntas() {
    const baseUrl='https://websfym.wwf.cl/api/preguntas/';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [preguntaSeleccionado, setPreguntaSeleccionado]=useState({
        id: '',
        preg_nombre: '',
        preg_apellido: '',
        preg_estatus: '',
        preg_pregunta: '',
        preg_email: '',
        preg_telefono: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setPreguntaSeleccionado((prevState)=>({
        ...prevState,
        [name]: value
        }))
        console.log(preguntaSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
            console.log(response);
        setData(response.data);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("preg_nombre", preguntaSeleccionado.nombre);
        f.append("preg_apellido", preguntaSeleccionado.apellido);
        f.append("preg_email", preguntaSeleccionado.email);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
        setData(data.concat(response.data));
        
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("preg_nombre", preguntaSeleccionado.preg_nombre);
        f.append("preg_apellido", preguntaSeleccionado.preg_apellido);
        f.append("preg_email", preguntaSeleccionado.preg_email);
        f.append("preg_estatus", preguntaSeleccionado.preg_estatus);
        f.append("preg_pregunta", preguntaSeleccionado.preg_pregunta);
        f.append("preg_telefono", preguntaSeleccionado.preg_telefono);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: preguntaSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(pregunta=>{
            if(pregunta.id===preguntaSeleccionado.id){
                pregunta.preg_nombre=preguntaSeleccionado.preg_nombre;
                pregunta.preg_apellido=preguntaSeleccionado.preg_apellido;
                pregunta.preg_email=preguntaSeleccionado.preg_email;
                pregunta.preg_estatus=preguntaSeleccionado.preg_estatus;
                pregunta.preg_pregunta=preguntaSeleccionado.preg_pregunta;
                pregunta.preg_telefono=preguntaSeleccionado.preg_telefono;
            }
        });
        setData(dataNueva);
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: preguntaSeleccionado.id}})
        .then(response=>{
        setData(data.filter(pregunta=>pregunta.id!==preguntaSeleccionado.id));
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarPregunta=(pregunta, caso)=>{
        setPreguntaSeleccionado(pregunta);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEliminar()
    }

    useEffect(()=>{
        peticionGet();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Preguntas</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Preguntas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    {/* <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div> */}
                                </div>
                                {/* /.lista de ELEMENTOS */}
                                <div className="card-body">
                                    <table id="preguntas" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre y Apellido</th>
                                                <th>Email</th>
                                                <th>Telefono</th>
                                                <th>Pregunta</th>
                                                <th>Fecha</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(pregunta=>(

                                            <tr key={pregunta.id}>
                                                <td>{pregunta.id}</td>
                                                <td>{pregunta.preg_nombre} {pregunta.preg_apellido}</td>
                                                <td>{pregunta.preg_email}</td>
                                                <td>{pregunta.preg_telefono}</td>
                                                <td>{pregunta.preg_pregunta}</td>
                                                <td>{pregunta.fecha}</td>
                                            <td>
                                            <button className="btn btn-primary" onClick={()=>seleccionarPregunta(pregunta, "Editar")}>Editar</button> {"  "}
                                            <button className="btn btn-danger" onClick={()=>seleccionarPregunta(pregunta, "Eliminar")}>Eliminar</button>
                                            </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    
                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="preg_nombre" onChange={handleChange} value={preguntaSeleccionado && preguntaSeleccionado.preg_nombre}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input type="text" className="form-control" name="preg_apellido" onChange={handleChange} value={preguntaSeleccionado && preguntaSeleccionado.preg_apellido}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control" name="preg_email" onChange={handleChange} value={preguntaSeleccionado && preguntaSeleccionado.preg_email}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Telefono</label>
                                                <input type="text" className="form-control" name="preg_telefono" onChange={handleChange} value={preguntaSeleccionado && preguntaSeleccionado.preg_telefono}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Pregunta</label>
                                                <input type="text" className="form-control" name="preg_pregunta" onChange={handleChange} value={preguntaSeleccionado && preguntaSeleccionado.preg_pregunta}/>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    {/* <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar el Usuario {elementoSeleccionado && elementoSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Preguntas;