import React from 'react'

export default function Footer() {
    return (
        <footer className="main-footer">
            <strong>Copyright © 2021 <a href="#">wwf</a>.</strong>
            Derechos reservados.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.1
            </div>
        </footer>
    )
}
