import React, {useState, useEffect} from 'react';

import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Campanas() {
    const baseUrl='https://websfym.wwf.cl/api/adopta/';
    const [data, setData]=useState([]);
    const [dataform, setDataform]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [campanaadoptaSeleccionado, setCampanaadoptaSeleccionado]=useState({         
        id: '',
        nombre:'',
        titulo_p: '',
        subtitulo_p: '',
        imagen_p: '',
        formulario_id: '',
        texto_s2:'',
        titulo_s2:'',
        estatus: '',
        html:'',
        html_regalo:'',
        html_receptor:''
    });   

    const handleChange=e=>{
        const {name, value}=e.target;
        setCampanaadoptaSeleccionado({
            ...campanaadoptaSeleccionado,
            [name]:value
        })
        console.log(campanaadoptaSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setCampanaadoptaSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        console.log(estatuslist)
    }

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setCampanaadoptaSeleccionado({
            ...campanaadoptaSeleccionado,
            [name]: files[0],
        })
        console.log(campanaadoptaSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }
    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }
    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        console.log(response);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", campanaadoptaSeleccionado.nombre);
        f.append("titulo_p", campanaadoptaSeleccionado.titulo_p);
        f.append("subtitulo_p", campanaadoptaSeleccionado.subtitulo_p,);
        f.append("imagen_p", campanaadoptaSeleccionado.imagen_p,);
        f.append("formulario_id", campanaadoptaSeleccionado.formulario_id);
        f.append("texto_s2", campanaadoptaSeleccionado.texto_s2);
        f.append("titulo_s2", campanaadoptaSeleccionado.titulo_s2);
        f.append("html", campanaadoptaSeleccionado.html);
        f.append("html_regalo", campanaadoptaSeleccionado.html_regalo);
        f.append("html_receptor", campanaadoptaSeleccionado.html_receptor);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
        setData(data.concat(response.data));
        peticionGet();
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
        setCampanaadoptaSeleccionado({
            id: '',
            nombre:'',
            titulo_p: '',
            subtitulo_p: '',
            imagen_p: '',
            formulario_id: '', 
            texto_s2:'',
            titulo_s2:'',
            estatus: '',
            html:'',
            html_regalo:'',
            html_receptor:''
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", campanaadoptaSeleccionado.nombre);
        f.append("titulo_p", campanaadoptaSeleccionado.titulo_p);
        f.append("subtitulo_p", campanaadoptaSeleccionado.subtitulo_p,);
        f.append("imagen_p", campanaadoptaSeleccionado.imagen_p,);
        f.append("formulario_id", campanaadoptaSeleccionado.formulario_id);
        f.append("texto_s2", campanaadoptaSeleccionado.texto_s2);
        f.append("titulo_s2", campanaadoptaSeleccionado.titulo_s2);
        f.append("html", campanaadoptaSeleccionado.html);
        f.append("html_regalo", campanaadoptaSeleccionado.html_regalo);
        f.append("html_receptor", campanaadoptaSeleccionado.html_receptor);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: campanaadoptaSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(campana=>{
            if(campana.id===campanaadoptaSeleccionado.id){
                campana.nombre=campanaadoptaSeleccionado.nombre;
                campana.titulo_p=campanaadoptaSeleccionado.titulo_p;
                campana.subtitulo_p=campanaadoptaSeleccionado.subtitulo_p;
                campana.imagen_p=campanaadoptaSeleccionado.imagen_p;
                campana.formulario_id=campanaadoptaSeleccionado.formulario_id;
            }
        });
        setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: campanaadoptaSeleccionado.id}})
        .then(response=>{
        setData(data.filter(campana=>campana.id!==campanaadoptaSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: campanaadoptaSeleccionado.id,nombre: campanaadoptaSeleccionado.nombre}})
        .then(response=>{
        setData(data.filter(campana=>campana.id!==campanaadoptaSeleccionado.id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setCampanaadoptaSeleccionado({
                ...campanaadoptaSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionGetFormularios=async()=>{
        var f = new FormData();
        f.append("METHOD", "GETFORMULARIOS");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
            setDataform(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const seleccionarCampanaadopta=(campana, caso)=>{
        setCampanaadoptaSeleccionado(campana);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const verCampana=(campana, nombre)=>{        
        window.open('https://websfym.wwf.cl/adopta/'+nombre, '_blank')
    }

    // useEffect(()=>{
    //     peticionGet();
    //     peticionGetFormularios();
    // },[])

    useEffect(()=>{
        setTimeout(() => peticionGet(), 500);
        peticionGetFormularios();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Campañas</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Adopta/Campañas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Titulo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(campana=>(
                                                <tr key={campana.id}>
                                                    <td>{campana.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/adopta/upload/'+campana.imagen_p} alt="Imagen" className="brand-image " style={{width: '100px'}} /></td>
                                                    <td>{campana.nombre}</td>
                                                    <td>{campana.titulo_p}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={campana.estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${campana.id}`} name={`estatus-${campana.id}`} data-id={campana.id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${campana.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarCampanaadopta(campana, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarCampanaadopta(campana, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarCampanaadopta(campana, "Eliminar")}>Eliminar</button>{"  "}
                                                        <button className="btn btn-info" onClick={()=>verCampana(campana, campana.nombre)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{campanaadoptaSeleccionado.imagen_p.name ? campanaadoptaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                    <br></br>
                                                    <span>2000px - 800px peso max:5000MB</span>
                                                </div>
                                            </div>                                            
                                            <div className="form-group">
                                                <label>Formulario</label>
                                                <select className="form-control select2 select2-hidden-accessible" name="formulario_id" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    <option value='0'>Selecciona Formulario</option>
                                                    {dataform?.map(formulario => (
                                                        <option key={formulario.form_id} value={formulario.form_id}>{formulario.form_nombre}</option>
                                                    ))}
                                                </select>
                                                <span>**Previamente creado en modulo Formularios</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal sección formulario</label>
                                                <input className="form-control" type="text" name="titulo_s2"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Texto complementario sección formulario</label>
                                                <input className="form-control" type="text" name="texto_s2"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>HTML Correo Adopción única</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html" id="html" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html">{campanaadoptaSeleccionado.html.name ? campanaadoptaSeleccionado.html.name : "Cargar HTML"}</label>
                                                </div>
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                           <div className="form-group">
                                                <label>HTML Correo Adopción 1 </label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html_regalo" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html_regalo">{campanaadoptaSeleccionado.html_regalo.name ? campanaadoptaSeleccionado.html_regalo.name : "Cargar HTML"}</label>
                                                </div>
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div> 
                                              <div className="form-group">
                                                <label>HTML Correo Adopción 2 </label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html_receptor" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html_receptor">{campanaadoptaSeleccionado.html_receptor.name ? campanaadoptaSeleccionado.html_receptor.name : "Cargar HTML"}</label>
                                                </div>
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={campanaadoptaSeleccionado && campanaadoptaSeleccionado.nombre}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange} value={campanaadoptaSeleccionado && campanaadoptaSeleccionado.titulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange} value={campanaadoptaSeleccionado && campanaadoptaSeleccionado.subtitulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{campanaadoptaSeleccionado.imagen_p.name ? campanaadoptaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_p">{typeof campanaadoptaSeleccionado.imagen_p==='string' ? campanaadoptaSeleccionado.imagen_p : ""}</label>
                                                <br></br>
                                                <span>2000px - 800px peso max:5000MB</span>
                                            </div>                                            
                                           
                                            <div className="form-group">
                                                <label>Formulario</label>
                                                <select  className="form-control select2 select2-hidden-accessible" name="formulario_id" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    {dataform?.map(formulario => (
                                                    <option key={formulario.form_id} value={formulario.form_id} select={campanaadoptaSeleccionado.formulario_id===formulario.form_id ? 'select' : ''}>{formulario.form_nombre}</option>
                                                ))}
                                                </select>
                                                <span>**Previamente creado en modulo Formularios</span>
                                            </div>
                                            <div className="form-group">
                                                <label>HTML Correo Adopción única</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html" id="html" value={campanaadoptaSeleccionado.html === '' ? campanaadoptaSeleccionado.html.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html">{campanaadoptaSeleccionado.html.name ? campanaadoptaSeleccionado.html.name : "Cargar HTML"}</label>
                                                </div>
                                                <label className="" for="html">{typeof campanaadoptaSeleccionado.html === 'string' ? campanaadoptaSeleccionado.html : ""}</label>
                                                <br />
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                            <div className="form-group">
                                                <label>HTML Correo Adopción regalo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html_regalo" id="html_regalo" value={campanaadoptaSeleccionado.html_regalo === '' ? campanaadoptaSeleccionado.html_regalo.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html_regalo">{campanaadoptaSeleccionado.html_regalo.name ? campanaadoptaSeleccionado.html_regalo.name : "Cargar HTML"}</label>
                                                </div>
                                                <label className="" for="html_regalo">{typeof campanaadoptaSeleccionado.html_regalo === 'string' ? campanaadoptaSeleccionado.html_regalo : ""}</label>
                                                <br />
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                            <div className="form-group">
                                                <label>HTML Correo Adopción receptor regalo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html_receptor" id="html_receptor" value={campanaadoptaSeleccionado.html_receptor === '' ? campanaadoptaSeleccionado.html_receptor.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html_receptor">{campanaadoptaSeleccionado.html_receptor.name ? campanaadoptaSeleccionado.html_receptor.name : "Cargar HTML"}</label>
                                                </div>
                                                <label className="" for="html_receptor">{typeof campanaadoptaSeleccionado.html_receptor === 'string' ? campanaadoptaSeleccionado.html_receptor : ""}</label>
                                                <br />
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {campanaadoptaSeleccionado && campanaadoptaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {campanaadoptaSeleccionado && campanaadoptaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Campanas;