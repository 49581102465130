import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Gracias() {
    const baseUrl='https://websfym.wwf.cl/api/dona/gracias.php';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    
    const [exitodonaSeleccionado, setExitodonaSeleccionado]=useState({         
        id: '',
        nombre:'',
        titulo_p: '',
        subtitulo_p: '',
        imagen_p: '',
        boton1: '',
        boton2: '',
        boton3: '',
        boton1_text: '',
        boton2_text: '',
        boton3_text: '',
        estatus: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setExitodonaSeleccionado({
            ...exitodonaSeleccionado,
            [name]:value
        })
        console.log(exitodonaSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setExitodonaSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        // console.log(!value);
        console.log(estatuslist)
    }

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setExitodonaSeleccionado({
            ...exitodonaSeleccionado,
            [name]: files[0],
        })
        console.log(exitodonaSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        console.log(response);
        }).catch(exito=>{
        console.log(exito);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", exitodonaSeleccionado.nombre);
        f.append("titulo_p", exitodonaSeleccionado.titulo_p);
        f.append("subtitulo_p", exitodonaSeleccionado.subtitulo_p,);
        f.append("imagen_p", exitodonaSeleccionado.imagen_p,);
        f.append("boton1", exitodonaSeleccionado.boton1);
        f.append("boton2", exitodonaSeleccionado.boton2);
        f.append("boton3", exitodonaSeleccionado.boton3);
        f.append("boton1_text", exitodonaSeleccionado.boton1_text);
        f.append("boton2_text", exitodonaSeleccionado.boton2_text);
        f.append("boton3_text", exitodonaSeleccionado.boton3_text);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
        setData(data.concat(response.data));
        peticionGet();
        abrirCerrarModalInsertar();
        }).catch(exito=>{
        console.log(exito);
        })
        setExitodonaSeleccionado({
            id: '',
            nombre:'',
            titulo_p: '',
            subtitulo_p: '',
            imagen_p: '',
            boton1: '',
            boton2: '',
            boton3: '',
            boton1_text: '',
            boton2_text: '',
            boton3_text: ''
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", exitodonaSeleccionado.nombre);
        f.append("titulo_p", exitodonaSeleccionado.titulo_p);
        f.append("subtitulo_p", exitodonaSeleccionado.subtitulo_p,);
        f.append("imagen_p", exitodonaSeleccionado.imagen_p,);
        f.append("boton1", exitodonaSeleccionado.boton1);
        f.append("boton2", exitodonaSeleccionado.boton2);
        f.append("boton3", exitodonaSeleccionado.boton3);
        f.append("boton1_text", exitodonaSeleccionado.boton1_text);
        f.append("boton2_text", exitodonaSeleccionado.boton2_text);
        f.append("boton3_text", exitodonaSeleccionado.boton3_text);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: exitodonaSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(exito=>{
            if(exito.id===exitodonaSeleccionado.id){
                exito.nombre=exitodonaSeleccionado.nombre;
                exito.titulo_p=exitodonaSeleccionado.titulo_p;
                exito.subtitulo_p=exitodonaSeleccionado.subtitulo_p;
                exito.imagen_p=exitodonaSeleccionado.imagen_p;
                exito.boton1=exitodonaSeleccionado.boton1;
                exito.boton2=exitodonaSeleccionado.boton2;
                exito.boton3=exitodonaSeleccionado.boton3;
            }
        });
        //setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(exito=>{
        console.log(exito);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: exitodonaSeleccionado.id}})
        .then(response=>{
        setData(data.filter(exito=>exito.id!==exitodonaSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(exito=>{
        console.log(exito);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setExitodonaSeleccionado({
                ...exitodonaSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: exitodonaSeleccionado.id,nombre: exitodonaSeleccionado.nombre}})
        .then(response=>{
        setData(data.filter(exito=>exito.id!==exitodonaSeleccionado.id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }


    const seleccionarExitodona=(exito, caso)=>{
        setExitodonaSeleccionado(exito);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const verCampana=(campana, nombre)=>{        
        window.open('https://dona.wwf.cl/gracias.php?camp='+nombre, '_blank')
    }

    // useEffect(()=>{
    //     peticionGet();
    // },[])

    useEffect(()=>{
        setTimeout(() => peticionGet(), 500);
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Paginas de exito Dona</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Dona/Exito</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Titulo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(exito=>(
                                                <tr key={exito.id}>
                                                    <td>{exito.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/dona/upload/'+exito.imagen_p} alt="Imagen" className="brand-image " style={{width: '100px'}} /></td>
                                                    <td>{exito.nombre}</td>
                                                    <td>{exito.titulo_p}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={exito.estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${exito.id}`} name={`estatus-${exito.id}`} data-id={exito.id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${exito.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarExitodona(exito, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarExitodona(exito, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarExitodona(exito, "Eliminar")}>Eliminar</button>{"  "}
                                                        <button className="btn btn-info" onClick={()=>verCampana(exito, exito.nombre)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{exitodonaSeleccionado.imagen_p.name ? exitodonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                    <br />
                                                    <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                                </div>
                                            </div>
                                            <span>Boton 1</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <span>Boton 2</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Boton 3</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton3"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton3_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.nombre}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input type="text" className="form-control" name="titulo_p" onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.titulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input type="text" className="form-control" name="subtitulo_p" onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.subtitulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" value={exitodonaSeleccionado.imagen_p==='' ? exitodonaSeleccionado.imagen_p.name: ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{exitodonaSeleccionado.imagen_p.name ? exitodonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_p">{typeof exitodonaSeleccionado.imagen_p==='string' ? exitodonaSeleccionado.imagen_p : ""}</label> 
                                                <br />
                                                <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                            </div>
                                           
                                            <span>Boton 1</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.boton1}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.boton1_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 2</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.boton2}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.boton2_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 3</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton3"  onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.boton3}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton3_text"  onChange={handleChange} value={exitodonaSeleccionado && exitodonaSeleccionado.boton3_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {exitodonaSeleccionado && exitodonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {exitodonaSeleccionado && exitodonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                            
                                        </ModalFooter>
                                    </Modal>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Gracias;