import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

function Captadores() {
    const baseUrl='https://websfym.wwf.cl/api/captadores/';
    const [data, setData]=useState([]);
    const [dataagencias, setDataAgencias]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [captadorSeleccionado, setCaptadorSeleccionado]=useState({
        cap_id: '',
        cap_nombre: '',
        cap_apellido: '',
        cap_email: '',
        cap_rut: '',
        cap_status: '',
        cap_codigo: '',
        cap_agencia: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setCaptadorSeleccionado((prevState)=>({
        ...prevState,
        [name]: value
        }))
        console.log(captadorSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setCaptadorSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("cap_nombre", captadorSeleccionado.cap_nombre);
        f.append("cap_apellido", captadorSeleccionado.cap_apellido);
        f.append("cap_email", captadorSeleccionado.cap_email);
        f.append("cap_rut", captadorSeleccionado.cap_rut);
        f.append("cap_status", captadorSeleccionado.cap_status);
        f.append("cap_codigo", captadorSeleccionado.cap_codigo);
        f.append("cap_agencia", captadorSeleccionado.cap_agencia);
        f.append("METHOD", "POST");
        // console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            setData(data.concat(response.data));
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("cap_nombre", captadorSeleccionado.cap_nombre);
        f.append("cap_apellido", captadorSeleccionado.cap_apellido);
        f.append("cap_email", captadorSeleccionado.cap_email);
        f.append("cap_rut", captadorSeleccionado.cap_rut);
        f.append("cap_status", captadorSeleccionado.cap_status);
        f.append("cap_codigo", captadorSeleccionado.cap_codigo);
        f.append("cap_agencia", captadorSeleccionado.cap_agencia);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {cap_id: captadorSeleccionado.cap_id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(captador=>{
            if(captador.cap_id===captadorSeleccionado.cap_id){
                captador.cap_nombre=captadorSeleccionado.cap_nombre;
                captador.cap_apellido=captadorSeleccionado.cap_apellido;
                captador.cap_email=captadorSeleccionado.cap_email;
                captador.cap_rut=captadorSeleccionado.cap_rut;
                captador.cap_status=captadorSeleccionado.cap_status;
                captador.cap_codigo=captadorSeleccionado.cap_codigo;
                captador.cap_agencia=captadorSeleccionado.cap_agencia;
            }
        });
        setData(dataNueva);
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {cap_id: captadorSeleccionado.cap_id}})
        .then(response=>{
        setData(data.filter(captador=>captador.cap_id!==captadorSeleccionado.cap_id));
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setCaptadorSeleccionado({
                ...captadorSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionGetAgencias=async()=>{
        var f = new FormData();
        f.append("METHOD", "GETAGENCIAS");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
            setDataAgencias(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const seleccionarCaptador=(captador, caso)=>{
        setCaptadorSeleccionado(captador);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEliminar()
    }

    const verCaptador=(captador, nombre)=>{        
        window.open('https://websfym.wwf.cl/captadores/?cap='+nombre, '_blank')
    }

    useEffect(()=>{
        peticionGet();
        peticionGetAgencias();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Captadores</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Captadores</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista de usuarios */}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre y Apellido</th>
                                                <th>Email</th>
                                                <th>Rut</th>
                                                <th>Codigo</th>
                                                <th>Agencia</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(captador=>(
                                                <tr key={captador.cap_id}>
                                                    <td>{captador.cap_id}</td>
                                                    <td>{captador.cap_nombre} {captador.cap_apellido}</td>
                                                    <td>{captador.cap_email}</td>
                                                    <td>{captador.cap_rut}</td>
                                                    <td>{captador.cap_codigo}</td>
                                                    <td>{captador.cap_agencia}</td>
                                                    
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={captador.cap_status === 'true' ? true : false} className="custom-control-input" id={`estatus-${captador.id}`} name={`estatus-${captador.id}`} data-id={captador.id} onChange={handleCheck} />
                                                            <label className="custom-control-label" htmlFor={`estatus-${captador.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary" onClick={()=>seleccionarCaptador(captador, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarCaptador(captador, "Eliminar")}>Eliminar</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="cap_nombre" value={captadorSeleccionado ? captadorSeleccionado.cap_nombre: ''} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input className="form-control" type="text" name="cap_apellido" value={captadorSeleccionado ? captadorSeleccionado.cap_apellido: ''} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input className="form-control" type="text" name="cap_email" value={captadorSeleccionado ? captadorSeleccionado.cap_email: ''} onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Rut</label>
                                                <input className="form-control" type="text" name="cap_rut" value={captadorSeleccionado ? captadorSeleccionado.cap_rut: ''} onChange={handleChange} />
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Estatus</label>
                                                <input className="form-control" type="text" name="cap_status" value={captadorSeleccionado ? captadorSeleccionado.cap_status: ''} onChange={handleChange} />
                                            </div> */}
                                            <div className="form-group">
                                                <label>Codigo</label>
                                                <input className="form-control" type="text" name="cap_codigo" value={captadorSeleccionado ? captadorSeleccionado.cap_codigo: ''} onChange={handleChange} />
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Agencia</label>
                                                <input className="form-control" type="text" name="cap_agencia" value={captadorSeleccionado ? captadorSeleccionado.cap_agencia: ''} onChange={handleChange} />
                                            </div> */}

                                           <div className="form-group">
                                                 <label>Agencia</label>
                                               <select className="form-control select2 select2-hidden-accessible" name="cap_agencia" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    <option value='0'>Selecciona Agencia</option>
                                                  {dataagencias?.map(agencia => (
                                                        <option key={agencia.ag_id} value={agencia.ag_id}>{agencia.ag_nombre}</option>
                                                  ))}
                                             </select>
                                               <span>**Previamente creado en modulo Agencias</span>
                                         </div> 

                                            
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="cap_nombre" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_nombre}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Apellido</label>
                                                <input type="text" className="form-control" name="cap_apellido" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_apellido}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="text" className="form-control" name="cap_email" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_email}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Rut</label>
                                                <input type="text" className="form-control" name="cap_rut" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_rut}/>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Estatus</label>
                                                <input type="text" className="form-control" name="cap_status" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_status}/>
                                            </div> */}
                                            <div className="form-group">
                                                <label>Codigo</label>
                                                <input type="text" className="form-control" name="cap_codigo" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_codigo}/>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Agencia</label>
                                                <input type="text" className="form-control" name="cap_agencia" onChange={handleChange} value={captadorSeleccionado && captadorSeleccionado.cap_agencia}/>
                                            </div> */}
                                            <div className="form-group">
                                                <label>Agencia</label>
                                                <select  className="form-control select2 select2-hidden-accessible" name="cap_agencia" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    {dataagencias?.map(agencia => (
                                                        <option key={agencia.form_id} value={agencia.ag_id} select={captadorSeleccionado.cap_agencia===agencia.ag_id ? 'select' : ''}>{agencia.ag_nombre}</option>
                                                    ))}
                                                </select>
                                                <span>**Previamente creado en modulo Agencias</span>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar el Usuario {captadorSeleccionado && captadorSeleccionado.cap_codigo}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Captadores;