import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Especies() {
    const baseUrl='https://websfym.wwf.cl/api/especies/';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [especieSeleccionado, setEspecieSeleccionado]=useState({
        id: '',
        nombre: '',
        orden: '',
        estatus: '',
        imagen: '',
        imagen_descri: '',
        imagen_caluga1: '',
        imagen_caluga2: '',
        imagen_certificado_mini: '',
        imagen_certificado: '',
        imagen_origami: '',
        pdf_origami: '',
        imagen_email: '',
        subtitulo_ep: '',
        texto_emovil: '',
        texto_ep: '',
        texto_es2: '',
        texto_es3: '',
        texto_es_caluga1: '',
        texto_es_caluga2: '',
        titulo_ep: '',
        titulo_es2: '',
        titulo_es3: '',
        titulo_es_caluga: ''
    });


    //const [especieSeleccionado, setEspecieSeleccionado] = useState({ previewimagen: ""});

    const handleChange2 = e => {
        if (e.target.files.length) {
            setEspecieSeleccionado({
                previewimagen: URL.createObjectURL(e.target.files[0]),
        });
        }
    };

    const handleChange=e=>{
        const {name, value}=e.target;
        setEspecieSeleccionado({
            ...especieSeleccionado,
            [name]:value
        })
        console.log(especieSeleccionado);
    }

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setEspecieSeleccionado({
            ...especieSeleccionado,
            [name]: files[0],
        })
        // console.log(files[0].type);
        // console.log(files[0].name);
        // console.log(files[0].size);
        console.log(especieSeleccionado);
    }

    // const handleChange=e=>{
    //     const {name, value}=e.target;
    //     setEspecieSeleccionado({
    //         ...especieSeleccionado,
    //         [name]:value
    //     })
    //     console.log(especieSeleccionado);
    // }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setEspecieSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        // console.log(!value);
        console.log(estatuslist)
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        console.log(response);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", especieSeleccionado.nombre);
        f.append("orden", especieSeleccionado.orden);
        f.append("imagen", especieSeleccionado.imagen,);
        f.append("imagen_descri", especieSeleccionado.imagen_descri);
        f.append("imagen_caluga1", especieSeleccionado.imagen_caluga1);
        f.append("imagen_caluga2", especieSeleccionado.imagen_caluga2);
        f.append("imagen_certificado_mini", especieSeleccionado.imagen_certificado_mini);
        f.append("imagen_certificado", especieSeleccionado.imagen_certificado);
        f.append("imagen_origami", especieSeleccionado.imagen_origami);
        f.append("imagen_email", especieSeleccionado.imagen_email);
     
        f.append("pdf_origami", especieSeleccionado.pdf_origami);

        f.append("subtitulo_ep", especieSeleccionado.subtitulo_ep);
        f.append("texto_emovil", especieSeleccionado.texto_emovil);
        f.append("texto_ep", especieSeleccionado.texto_ep);
        f.append("texto_es2", especieSeleccionado.texto_es2);
        f.append("texto_es3", especieSeleccionado.texto_es3);
        f.append("texto_es_caluga1", especieSeleccionado.texto_es_caluga1);
        f.append("texto_es_caluga2", especieSeleccionado.texto_es_caluga2);
        f.append("titulo_ep", especieSeleccionado.titulo_ep);
        f.append("titulo_es2", especieSeleccionado.titulo_es2);
        f.append("titulo_es3", especieSeleccionado.titulo_es3);
        f.append("titulo_es_caluga", especieSeleccionado.titulo_es_caluga);
        //console.log(f);
        f.append("METHOD", "POST");
        
        await axios.post(baseUrl, f)
        .then(response=>{
            // console.log(response);
        setData(data.concat(response.data));
        peticionGet();
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
        setEspecieSeleccionado({
            id: '',
            nombre: '',
            orden: '',
            estatus: '',
            imagen: '',
            imagen_descri: '',
            imagen_caluga1: '',
            imagen_caluga2: '',
            imagen_certificado_mini: '',
            imagen_certificado: '',
            imagen_origami: '',
            pdf_origami: '',
            imagen_email: '',
            subtitulo_ep: '',
            texto_emovil: '',
            texto_ep: '',
            texto_es2: '',
            texto_es3: '',
            texto_es_caluga1: '',
            texto_es_caluga2: '',
            titulo_ep: '',
            titulo_es2: '',
            titulo_es3: '',
            titulo_es_caluga: ''
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", especieSeleccionado.nombre);
        f.append("estatus", especieSeleccionado.estatus);
        f.append("orden", especieSeleccionado.orden);
        f.append("imagen", especieSeleccionado.imagen);
        f.append("imagen_descri", especieSeleccionado.imagen_descri);
        f.append("imagen_caluga1", especieSeleccionado.imagen_caluga1);
        f.append("imagen_certificado_mini", especieSeleccionado.imagen_certificado);
        f.append("imagen_origami", especieSeleccionado.imagen_origami);
        f.append("imagen_email", especieSeleccionado.imagen_email);
        f.append("textos", especieSeleccionado.textos);
        f.append("pdf_origami", especieSeleccionado.pdf_origami);
        f.append("subtitulo_ep", especieSeleccionado.subtitulo_ep);
        f.append("texto_emovil", especieSeleccionado.texto_emovil);
        f.append("texto_ep", especieSeleccionado.texto_ep);
        f.append("texto_es2", especieSeleccionado.texto_es2);
        f.append("texto_es3", especieSeleccionado.texto_es3);
        f.append("texto_es_caluga1", especieSeleccionado.texto_es_caluga1);
        f.append("texto_es_caluga2", especieSeleccionado.texto_es_caluga2);
        f.append("titulo_ep", especieSeleccionado.titulo_ep);
        f.append("titulo_es2", especieSeleccionado.titulo_es2);
        f.append("titulo_es3", especieSeleccionado.titulo_es3);
        f.append("titulo_es_caluga", especieSeleccionado.titulo_es_caluga);
        f.append("METHOD", "PUT");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id: especieSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(especie=>{
            if(especie.id===especieSeleccionado.id){
                especie.nombre=especieSeleccionado.nombre;
                especie.estatus=especieSeleccionado.estatus;
                especie.orden=especieSeleccionado.orden;
                especie.imagen=especieSeleccionado.imagen;
                especie.imagen_descri=especieSeleccionado.imagen_descri;
                especie.imagen_caluga2=especieSeleccionado.imagen_caluga2;
                especie.imagen_certificado=especieSeleccionado.imagen_certificado;
                especie.imagen_origami=especieSeleccionado.imagen_origami;
                especie.imagen_email=especieSeleccionado.imagen_email;
                
                especie.pdf_origami=especieSeleccionado.pdf_origami;

                especie.subtitulo_ep=especieSeleccionado.subtitulo_ep;
                especie.texto_emovil=especieSeleccionado.texto_emovil;
                especie.texto_ep=especieSeleccionado.texto_ep;
                especie.texto_es2=especieSeleccionado.texto_es2;
                especie.texto_es3=especieSeleccionado.texto_es3;
                especie.texto_es_caluga1=especieSeleccionado.texto_es_caluga1;
                especie.texto_es_caluga2=especieSeleccionado.texto_es_caluga2;
                especie.titulo_ep=especieSeleccionado.titulo_ep;
                especie.titulo_es2=especieSeleccionado.titulo_es2;
                especie.titulo_es3=especieSeleccionado.titulo_es3;
                especie.titulo_es_caluga=especieSeleccionado.titulo_es_caluga;
            }
        });
        //setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        //console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})

        .then(response=>{
        var dataNueva= data;
        const a=`estatus-${id}`
        setEspecieSeleccionado({
            ...especieSeleccionado,
            [a]:check
        })
        peticionGet();
        //setData(dataNueva);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: especieSeleccionado.id}})
        .then(response=>{
        setData(data.filter(especie=>especie.id!==especieSeleccionado.id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: especieSeleccionado.id}})
        .then(response=>{
        setData(data.filter(especie=>especie.id!==especieSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarEspecie=(especie, caso)=>{
        setEspecieSeleccionado(especie);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    // const verEspecie=(especie, nombre)=>{        
    //     window.open('https://websfym.wwf.cl/wwfnew/?camp='+nombre, '_blank')
    // }

    useEffect(()=>{
        setTimeout(() => peticionGet(), 500);
        //peticionGet();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Especies</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Especies</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>orden</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(especie=>(
                                                <tr key={especie.id}>
                                                    <td>{especie.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/especies/upload/'+especie.imagen} alt="Imagen" className="brand-image " style={{width: '100px'}} /></td>
                                                    <td>{especie.nombre}</td>
                                                    <td>{especie.orden}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={especie.estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${especie.id}`} name={`estatus-${especie.id}`} data-id={especie.id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${especie.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarEspecie(especie, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarEspecie(especie, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarEspecie(especie, "Eliminar")}>Eliminar</button>{" "}
                                                        {/* <button className="btn btn-info" onClick={()=>verEspecie(especie, especie.nombre)}>Ver</button> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Orden</label>
                                                <input className="form-control" type="text" name="orden"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen" id="imagen" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen">{especieSeleccionado.imagen.name ? especieSeleccionado.imagen.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen descripción</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_descri" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_descri">{especieSeleccionado.imagen_descri.name ? especieSeleccionado.imagen_descri.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div> 
                                            <div className="form-group">
                                                <label>Imagen caluga 1</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_caluga1"  onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.imagen_caluga1.name ? especieSeleccionado.imagen_caluga1.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen caluga 2</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_caluga2" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.imagen_caluga2.name ? especieSeleccionado.imagen_caluga2.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen Certificado pdf</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_certificado" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.imagen_certificado.name ? especieSeleccionado.imagen_certificado.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen Certificado ejemplo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_certificado_mini" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.imagen_certificado_mini.name ? especieSeleccionado.imagen_certificado_mini.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen origami ejemplo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_origami" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.imagen_origami.name ? especieSeleccionado.imagen_origami.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>PDF Origami</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="pdf_origami" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.pdf_origami.name ? especieSeleccionado.pdf_origami.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div>
                                            {/* <div className="form-group">
                                                <label>Imagen Email</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_email" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="exampleInputFile">{especieSeleccionado.imagen_email.name ? especieSeleccionado.imagen_email.name : "Cargar Imagen"}</label>
                                                </div>
                                            </div> */}
                                            
                                            <div className="form-group">
                                                <label>Titulo</label>
                                                <input className="form-control" type="text" name="titulo_ep" onChange={handleChange} />
                                            </div>

                                            <div className="form-group">
                                                <label>subtitulo</label>
                                                <input className="form-control" type="text" name="subtitulo_ep" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>texto 1</label>
                                                <input className="form-control" type="text" name="texto_ep" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo</label>
                                                <input className="form-control" type="text" name="titulo_es2" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>texto 2</label>
                                                <input className="form-control" type="text" name="texto_es2" onChange={handleChange} />
                                            </div>

                                            <div className="form-group">
                                                <label>Titulo</label>
                                                <input className="form-control" type="text" name="titulo_es3" onChange={handleChange} />
                                            </div>

                                            <div className="form-group">
                                                <label>texto 3</label>
                                                <input className="form-control" type="text" name="texto_es3" onChange={handleChange} />
                                            </div>

                                            <div className="form-group">
                                                <label>titulo caluga</label>
                                                <input className="form-control" type="text" name="titulo_es_caluga" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>caluga 1</label>
                                                <input className="form-control" type="text" name="texto_es_caluga1" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>caluga 2</label>
                                                <input className="form-control" type="text" name="texto_es_caluga2" onChange={handleChange} />
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.nombre}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Orden</label>
                                                <input type="text" className="form-control" name="orden" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.orden}/>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen" id="imagen" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen">{especieSeleccionado.imagen.name ? especieSeleccionado.imagen.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen">{typeof especieSeleccionado.imagen==='string' ? especieSeleccionado.imagen : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen descripción</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_descri" id="imagen_descri" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_descri">{especieSeleccionado.imagen_descri.name ? especieSeleccionado.imagen_descri.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_descri">{typeof especieSeleccionado.imagen_descri==='string' ? especieSeleccionado.imagen_descri : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen caluga 1</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_caluga1" id="imagen_caluga1" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_caluga1">{especieSeleccionado.imagen_caluga1.name ? especieSeleccionado.imagen_caluga1.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_caluga1">{typeof especieSeleccionado.imagen_caluga1==='string' ? especieSeleccionado.imagen_caluga1 : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen caluga 2</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_caluga2" id="imagen_caluga2" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_caluga2">{especieSeleccionado.imagen_caluga2.name ? especieSeleccionado.imagen_caluga2.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_caluga2">{typeof especieSeleccionado.imagen_caluga2==='string' ? especieSeleccionado.imagen_caluga2 : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen Certificado pdf</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_certificado" id="imagen_certificado" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_certificado">{especieSeleccionado.imagen_certificado.name ? especieSeleccionado.imagen_certificado.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_certificado">{typeof especieSeleccionado.imagen_certificado==='string' ? especieSeleccionado.imagen_certificado : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen Certificado ejemplo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_certificado_mini" id="imagen_certificado_mini" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_certificado_mini">{especieSeleccionado.imagen_certificado_mini.name ? especieSeleccionado.imagen_certificado_mini.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_certificado_mini">{typeof especieSeleccionado.imagen_certificado_mini==='string' ? especieSeleccionado.imagen_certificado_mini : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen Origami Ejemplo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_origami" id="imagen_origami" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_origami">{especieSeleccionado.imagen_origami.name ? especieSeleccionado.imagen_origami.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_origami">{typeof especieSeleccionado.imagen_origami==='string' ? especieSeleccionado.imagen_origami : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>PDF Origami</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="pdf_origami" id="pdf_origami" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="pdf_origami">{especieSeleccionado.pdf_origami.name ? especieSeleccionado.pdf_origami.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="pdf_origami">{typeof especieSeleccionado.pdf_origami==='string' ? especieSeleccionado.pdf_origami : ""}</label> 
                                            </div>
                                            
                                            {/* <div className="form-group">
                                                <label>Imagen Email</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_email" id="imagen_email" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_email">{especieSeleccionado.imagen_email.name ? especieSeleccionado.imagen_email.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_email">{typeof especieSeleccionado.imagen_email==='string' ? especieSeleccionado.imagen_email : ""}</label> 
                                            </div> */}

                                            <div className="form-group">
                                                <label>Titulo</label>
                                                <input type="text" className="form-control" name="titulo_ep" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.titulo_ep}/>
                                            </div>

                                            <div className="form-group">
                                            <label>subtitulo</label>
                                            <input type="text" className="form-control" name="subtitulo_ep" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.subtitulo_ep}/>
                                            </div>

                                            <div className="form-group">
                                            <label>texto 1</label>
                                            <input type="text" className="form-control" name="texto_ep" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.texto_ep}/>
                                            </div>

                                            <div className="form-group">
                                                <label>Titulo</label>
                                                <input type="text" className="form-control" name="titulo_es2" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.titulo_es2}/>
                                            </div>
                                            <div className="form-group">
                                            <label>texto 2</label>
                                            <input type="text" className="form-control" name="texto_es2" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.texto_es2}/>
                                            </div>

                                            <div className="form-group">
                                                <label>Titulo</label>
                                                <input type="text" className="form-control" name="titulo_es3" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.titulo_es3}/>
                                            </div>

                                            <div className="form-group">
                                            <label>texto 3</label>
                                            <input type="text" className="form-control" name="texto_es3" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.texto_es3}/>
                                            </div>

                                            <div className="form-group">
                                            <label>titulo caluga</label>
                                            <input type="text" className="form-control" name="titulo_es_caluga" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.titulo_es_caluga}/>
                                            </div>

                                            <div className="form-group">
                                            <label>caluga 1</label>
                                            <input type="text" className="form-control" name="texto_es_caluga1" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.texto_es_caluga1}/>
                                            </div>

                                            <div className="form-group">
                                            <label>caluga 2</label>
                                            <input type="text" className="form-control" name="texto_es_caluga2" onChange={handleChange} value={especieSeleccionado && especieSeleccionado.texto_es_caluga2}/>
                                            </div>

                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {especieSeleccionado && especieSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {especieSeleccionado && especieSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Especies;