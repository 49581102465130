import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

function Agencias() {
    const baseUrl='https://websfym.wwf.cl/api/agencias/';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [agenciaSeleccionado, setAgenciaSeleccionado]=useState({
        ag_id: '',
        ag_nombre: '',
        ag_status: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setAgenciaSeleccionado((prevState)=>({
        ...prevState,
        [name]: value
        }))
        console.log(agenciaSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setAgenciaSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        console.log(estatuslist)
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("ag_nombre", agenciaSeleccionado.ag_nombre);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
        setData(data.concat(response.data));
        
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("ag_nombre", agenciaSeleccionado.ag_nombre);
        f.append("ag_status", agenciaSeleccionado.ag_status);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {ag_id: agenciaSeleccionado.ag_id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(agencia=>{
            if(agencia.id===agenciaSeleccionado.id){
                agencia.ag_status=agenciaSeleccionado.ag_status;
                agencia.ag_status=agenciaSeleccionado.ag_status;
            }
        });
        setData(dataNueva);
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {ag_id: agenciaSeleccionado.ag_id}})
        .then(response=>{
        setData(data.filter(agencia=>agencia.ag_id!==agenciaSeleccionado.ag_id));
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setAgenciaSeleccionado({
                ...agenciaSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarAgencia=(agencia, caso)=>{
        setAgenciaSeleccionado(agencia);
        (caso==="Editar")?
        abrirCerrarModalEditar():
        abrirCerrarModalEliminar()
    }

    useEffect(()=>{
        peticionGet();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Agencias</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Agencias</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista */}
                                <div className="card-body">
                                    <table id="agencias" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nombre</th>
                                                <th>status</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(agencia=>(
                                                <tr key={agencia.ag_id}>
                                                    <td>{agencia.ag_id}</td>
                                                    <td>{agencia.ag_nombre}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={agencia.ag_status==='true' ? true : false} className="custom-control-input" id={`estatus-${agencia.id}`} name={`estatus-${agencia.id}`} data-id={agencia.id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${agencia.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarAgencia(agencia, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarAgencia(agencia, "Eliminar")}>Eliminar</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="ag_nombre" value={agenciaSeleccionado ? agenciaSeleccionado.ag_nombre: ''} onChange={handleChange}/>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="ag_nombre" onChange={handleChange} value={agenciaSeleccionado && agenciaSeleccionado.ag_nombre}/>
                                            </div>
                                           {/*<div className="form-group">
                                                <label>Estatus</label>
                                                <input type="text" className="form-control" name="ag_status" onChange={handleChange} value={agenciaSeleccionado && agenciaSeleccionado.ag_status}/>
                                            </div> */} 
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar la agencia {agenciaSeleccionado && agenciaSeleccionado.ag_nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                        <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                            Sí
                                        </button>
                                        <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                            No
                                        </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Agencias;