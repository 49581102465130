import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Campanas() {
    const baseUrl='https://websfym.wwf.cl/api/dona/';
    const [data, setData]=useState([]);
    const [dataform, setDataform]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [campanadonaSeleccionado, setCampanadonaSeleccionado]=useState({         
        id: '',
        nombre:'',
        titulo_p: '',
        subtitulo_p: '',
        imagen_p: '',
        titulo_s: '',
        texto_s: '',
        imagen_f: '',
        formulario_id: '',
        estatus: '',
        html:''
    });  
    
    const handleChange=e=>{
        const {name, value}=e.target;
        setCampanadonaSeleccionado({
            ...campanadonaSeleccionado,
            [name]:value
        })
        console.log(campanadonaSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setCampanadonaSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        console.log(estatuslist)
    }

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setCampanadonaSeleccionado({
            ...campanadonaSeleccionado,
            [name]: files[0],
        })
        console.log(campanadonaSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }
    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }
    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
            console.log(response);
            setData(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", campanadonaSeleccionado.nombre);
        f.append("titulo_p", campanadonaSeleccionado.titulo_p);
        f.append("subtitulo_p", campanadonaSeleccionado.subtitulo_p,);
        f.append("imagen_p", campanadonaSeleccionado.imagen_p,);
        f.append("titulo_s", campanadonaSeleccionado.titulo_s);
        f.append("texto_s", campanadonaSeleccionado.texto_s);
        f.append("imagen_f", campanadonaSeleccionado.imagen_f);
        f.append("formulario_id", campanadonaSeleccionado.formulario_id);
        f.append("html", campanadonaSeleccionado.html);
        f.append("METHOD", "POST");
        //console.log(f);
        await axios.post(baseUrl,f)
        .then(response=>{
            // console.log(response);
            setData(data.concat(response.data));
            peticionGet();
            abrirCerrarModalInsertar();
        }).catch(error=>{
            console.log(error);
        })
        // setCampanadonaSeleccionado({
        //     id: '',
        //     nombre:'',
        //     titulo_p: '',
        //     subtitulo_p: '',
        //     imagen_p: '',
        //     titulo_s: '',
        //     texto_s: '',
        //     imagen_f: '',
        //     formulario_id: ''
        // })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", campanadonaSeleccionado.nombre);
        f.append("titulo_p", campanadonaSeleccionado.titulo_p);
        f.append("subtitulo_p", campanadonaSeleccionado.subtitulo_p,);
        f.append("imagen_p", campanadonaSeleccionado.imagen_p,);
        f.append("titulo_s", campanadonaSeleccionado.titulo_s);
        f.append("texto_s", campanadonaSeleccionado.texto_s);
        f.append("imagen_f", campanadonaSeleccionado.imagen_f);
        f.append("formulario_id", campanadonaSeleccionado.formulario_id);
        f.append("html", campanadonaSeleccionado.html);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: campanadonaSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(campana=>{
            if(campana.id===campanadonaSeleccionado.id){
                campana.nombre=campanadonaSeleccionado.nombre;
                campana.titulo_p=campanadonaSeleccionado.titulo_p;
                campana.subtitulo_p=campanadonaSeleccionado.subtitulo_p;
                campana.imagen_p=campanadonaSeleccionado.imagen_p;
                campana.titulo_s=campanadonaSeleccionado.titulo_s;
                campana.texto_s=campanadonaSeleccionado.texto_s;
                campana.imagen_f=campanadonaSeleccionado.imagen_f;
                campana.formulario_id=campanadonaSeleccionado.formulario_id;
            }
        });
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: campanadonaSeleccionado.id}})
        .then(response=>{
        setData(data.filter(campana=>campana.id!==campanadonaSeleccionado.id));
        abrirCerrarModalEliminar();
        peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: campanadonaSeleccionado.id,nombre: campanadonaSeleccionado.nombre}})
        .then(response=>{
        setData(data.filter(campana=>campana.id!==campanadonaSeleccionado.id));
        abrirCerrarModalDuplicar();
        peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setCampanadonaSeleccionado({
                ...campanadonaSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionGetFormularios=async()=>{
        var f = new FormData();
        f.append("METHOD", "GETFORMULARIOS");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
            setDataform(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const seleccionarCampanadona=(campana, caso)=>{
        setCampanadonaSeleccionado(campana);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }

        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const verCampana=(campana, nombre)=>{        
        window.open('https://dona.wwf.cl/?camp='+nombre, '_blank')
    }


    useEffect(()=>{
        setTimeout(() => peticionGet(), 1);
        peticionGetFormularios();
    },[])
    
    // useEffect(()=>{
    //     setTimeout(() => peticionGet(), 1000);
    //     //peticionGet();
    // },[data])
    return( 
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Campañas</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                                <li className="breadcrumb-item active">Dona/Campañas</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={() => abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Titulo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(campana => (
                                                <tr key={campana.id}>
                                                    <td>{campana.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/dona/upload/' + campana.imagen_p} alt="Imagen" className="brand-image " style={{ width: '100px' }} /></td>
                                                    <td>{campana.nombre}</td>
                                                    <td>{campana.titulo_p}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={campana.estatus === 'true' ? true : false} className="custom-control-input" id={`estatus-${campana.id}`} name={`estatus-${campana.id}`} data-id={campana.id} onChange={handleCheck} />
                                                            <label className="custom-control-label" htmlFor={`estatus-${campana.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={() => seleccionarCampanadona(campana, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarCampanadona(campana, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={() => seleccionarCampanadona(campana, "Eliminar")}>Eliminar</button> {"  "}
                                                        <button className="btn btn-info" onClick={() => verCampana(campana, campana.nombre)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal size="lg"  isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre" onChange={handleChange} />
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{campanadonaSeleccionado.imagen_p.name ? campanadonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo Secundario</label>
                                                <input className="form-control" type="text" name="titulo_s" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Texto Secundario</label>
                                                <input className="form-control" type="text" name="texto_s" onChange={handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen fondo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_f" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_f">{campanadonaSeleccionado.imagen_f.name ? campanadonaSeleccionado.imagen_f.name : "Cargar Imagen"}</label>
                                                </div>
                                                <span>Sugerido: 2500px - 800px peso max:5000MB</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Formulario</label>
                                                <select className="form-control select2 select2-hidden-accessible" name="formulario_id" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    <option value='0'>Selecciona Formulario</option>
                                                    {dataform?.map(formulario => (
                                                        <option key={formulario.form_id} value={formulario.form_id}>{formulario.form_nombre}</option>
                                                    ))}
                                                </select>
                                                <span>**Previamente creado en modulo Formularios</span>
                                            </div>
                                          
                                            <div className="form-group">
                                                <label>HTML Correo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html">{campanadonaSeleccionado.html.name ? campanadonaSeleccionado.html.name : "Cargar HTML"}</label>
                                                </div>
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                           
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={() => peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.nombre} />
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input type="text" className="form-control" name="titulo_p" onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_p} />
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input type="text" className="form-control" name="subtitulo_p" onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.subtitulo_p} />
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" value={campanadonaSeleccionado.imagen_p === '' ? campanadonaSeleccionado.imagen_p.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{campanadonaSeleccionado.imagen_p.name ? campanadonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_p">{typeof campanadonaSeleccionado.imagen_p === 'string' ? campanadonaSeleccionado.imagen_p : ""}</label>
                                                <br />
                                                <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo Secundario</label>
                                                <input type="text" className="form-control" name="titulo_s" onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_s} />
                                            </div>
                                            <div className="form-group">
                                                <label>Texto Secundario</label>
                                                <input type="text" className="form-control" name="titulo_s" onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.texto_s} />
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen fondo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_f" id="imagen_f" value={campanadonaSeleccionado.imagen_f === '' ? campanadonaSeleccionado.imagen_f.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_f">{campanadonaSeleccionado.imagen_f.name ? campanadonaSeleccionado.imagen_f.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_f">{typeof campanadonaSeleccionado.imagen_f === 'string' ? campanadonaSeleccionado.imagen_f : ""}</label>
                                                <br />
                                                <span>Sugerido: 2500px - 800px peso max:5000MB</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Formulario</label>
                                                <select  className="form-control select2 select2-hidden-accessible" name="formulario_id" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    {dataform?.map(formulario => (
                                                    <option key={formulario.form_id} value={formulario.form_id} select={campanadonaSeleccionado.formulario_id===formulario.form_id ? 'select' : ''}>{formulario.form_nombre}</option>
                                                ))}
                                                </select>
                                                <span>**Previamente creado en modulo Formularios</span>
                                            </div>

                                            <div className="form-group">
                                                <label>HTML Correo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html" id="html" value={campanadonaSeleccionado.html === '' ? campanadonaSeleccionado.html.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html">{campanadonaSeleccionado.html.name ? campanadonaSeleccionado.html.name : "Cargar HTML"}</label>
                                                </div>
                                                <label className="" for="html">{typeof campanadonaSeleccionado.html === 'string' ? campanadonaSeleccionado.html : ""}</label>
                                                <br />
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={() => peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {campanadonaSeleccionado && campanadonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {campanadonaSeleccionado && campanadonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Campanas;