import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import App from './App.js';

render(

<React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)