import React, { Component } from "react";
import { Redirect, Switch, Route, Link } from "react-router-dom";
import { withRouter } from "react-router";
import IndexDashboard from "./IndexDashboard";
import NotFound from "./404";
import Menu from './Menu';
import Footer from './Footer';
import Especies from './modulos/Especies';
import Usuarios from './modulos/Usuarios';
import Socios from './modulos/Socios';
import Preguntas from './modulos/Preguntas';
import Agencias from './modulos/Agencias';
import Captadores from './modulos/Captadores';
import Telemarketing from './modulos/Telemarketing';
import Formularios from './modulos/Formularios';

import CampanasAdopta from './modulos/adopta/Campanas';
import GraciasAdopta from './modulos/adopta/Gracias.js';
import ErrorAdopta from './modulos/adopta/Error.js';

import CampanasDona from './modulos/dona/Campanas.js';
import ErrorDona from './modulos/dona/Error.js';
import GraciasDona from './modulos/dona/Gracias.js';

import Campanas from './modulos/haztesocio/Campanas.js';
import Error from './modulos/haztesocio/Error.js';
import Gracias from './modulos/haztesocio/Gracias.js';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        islogout: false
        };
    }
    signOut = () => {
        localStorage.removeItem("token");
        this.setState({
        islogout: true
        });
    };
    render() {
        if (this.state.islogout) {
            return <Redirect to="/login" />;
        }
        const { match } = this.props;
        return (
            <div class="wrapper">
                {/* Menu header inicio*/}
                <nav className="main-header  navbar navbar-expand navbar-white navbar-light">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <Link className="nav-link" to={`${match.path}`}>Inicio</Link>
                            {/* <Link className="nav-link" to="/inicio">Inicio</Link> */}
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <Link className="nav-link" to={`${match.path}/usuarios`}>Usuarios</Link>
                        </li>
                        {/* <li className="nav-item d-none d-sm-inline-block">
                            <Link className="nav-link" to={`${match.path}/especies`}>Especies</Link>
                        </li> */}
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item d-none d-sm-inline-block">
                            {/* <a onClick={this.logout} className="nav-link" data-toggle="dropdown" href="#" >
                                salir
                            </a> */}
                            <a   className="nav-link" onClick={this.signOut} href="#">
                                Salir
                            </a>
                        </li>
                    </ul>
                </nav>
                {/* Menu header fin*/}

                {/*MENU sidebar INICIO*/}
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    
                    <Link className="brand-link" to="/admin">
                        {/* <img src="%PUBLIC_URL%/favicon.ico" alt="Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />     */}
                        <span className="brand-text font-weight-light">WWF Chile</span>
                    </Link>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) 
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                
                            </div>
                            <div className="info">
                                <a href="#" className="d-block">USUARIO</a>
                            </div>
                        </div>*/}
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
                                with font-awesome or any other icon font library  */}
                                <li className="nav-item has-treeview ">
                                    <a className="nav-link">
                                        <i >D</i>
                                        <p>
                                            ona<i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`${match.path}/donacampanas`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Campañas</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`${match.path}/donagracias`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Gracias</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={`${match.path}/donaerror`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Error</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                

                                <li className="nav-item has-treeview ">
                                    <a className="nav-link ">
                                    <i >A</i>
                                        <p>
                                            dopta<i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/especies`} >
                                                <i className="far fa-circle nav-icon" />
                                                <p>Especies</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/adoptacampanas`} >
                                                <i className="far fa-circle nav-icon" />
                                                <p>Campañas</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/adoptagracias`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Gracias</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/adoptaerror`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Error</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/preguntas`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Preguntas</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item has-treeview ">
                                    <a className="nav-link ">
                                        <i>H</i>
                                        <p>
                                            azte Socio<i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/haztesociocampanas`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Campañas</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/haztesociogracias`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Gracias</p>
                                            </Link>
                                        </li> 
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/haztesocioerror`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Error</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/agencias`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Agencias</p>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/captadores`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Captadores</p>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link className="nav-link"  to={`${match.path}/telemarketing`}>
                                                <i className="far fa-circle nav-icon" />
                                                <p>Telemarketing</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li> 
                                <li className="nav-item">
                                    <Link className="nav-link"  to={`${match.path}/socios`}>
                                        <i className="far fa-handshake nav-icon" />
                                        <p>Socios</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`${match.path}/formularios`}>
                                        <i class="nav-icon far fa-edit "></i>
                                        <p>Formularios</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`${match.path}/usuarios`}>
                                        <i class="nav-icon far fa-user "></i>
                                        <p>Usuarios</p>
                                    </Link>
                                </li>

                              
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
                {/*MENU sidebar FIN*/}

                {/*RUTAS MODULOS INICIO*/}
                <div className="content-wrapper">
                    <Switch>
                        <Route path={`${match.path}/especies`} >
                            <Especies />
                        </Route>
                        <Route path={`${match.path}/socios`} >
                            <Socios />
                        </Route>
                        <Route path={`${match.path}/preguntas`}>
                            <Preguntas />
                        </Route>
                        <Route path={`${match.path}/agencias`}>
                            <Agencias />
                        </Route>
                        <Route path={`${match.path}/captadores`}>
                            <Captadores />
                        </Route>
                        <Route path={`${match.path}/telemarketing`}>
                            <Telemarketing />
                        </Route>
                        <Route path={`${match.path}/formularios`}>
                            <Formularios />
                        </Route>
                        <Route path={`${match.path}/adoptagracias`}>
                            <GraciasAdopta />
                        </Route>
                        <Route path={`${match.path}/adoptaerror`}>
                            <ErrorAdopta />
                        </Route>
                        <Route path={`${match.path}/adoptacampanas`}>
                            <CampanasAdopta />
                        </Route>
                        <Route path={`${match.path}/donagracias`}>
                            <GraciasDona />
                        </Route>
                        <Route path={`${match.path}/donaerror`}>
                            <ErrorDona />
                        </Route>
                        <Route path={`${match.path}/donacampanas`}>
                            <CampanasDona />
                        </Route>
                        <Route path={`${match.path}/haztesociogracias`}>
                            <Gracias />
                        </Route>
                        <Route path={`${match.path}/haztesocioerror`}>
                            <Error />
                        </Route>
                        <Route path={`${match.path}/haztesociocampanas`}>
                            <Campanas />
                        </Route>
                        <Route path={`${match.path}/usuarios`}>
                            <Usuarios />
                        </Route>
                        <Route exact path={`${match.path}`}>
                            <IndexDashboard />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
                {/*RUTAS MODULOS FIN*/}
                <Footer/>
            </div>
        );
    }
}

export default withRouter(Dashboard);
