import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
//import AsyncStorage from '@react-native-community/async-storage';

const END_POINT='https://websfym.wwf.cl/app-wwf/src/api/Login/';

export default class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
         email: '',
         clave: '',
        //  redirectToReferrer: false
         islogged: false
        };

        this.Login=this.Login.bind(this);
        this.onChange=this.onChange.bind(this);
    }
    
    Login = () =>{
        const {email}=this.state;
        const {clave}=this.state;
        fetch('https://websfym.wwf.cl/api/Login/',{
            method: "POST",
            header: {
                'Access-Control-Allow-Origin':'*',
                'Content-type':'application/json'
            },
            body:JSON.stringify({
                email:email,
                clave:clave,
            })
        })
        .then((respuesta)=>respuesta.json())
        .then((responseJson) =>{
            if(responseJson=="ok"){
                //alert(responseJson);
                localStorage.setItem('token','86');
                // this.setState({redirectToReferrer: true});
                this.setState({
                    islogged: true
                  });
                //console.log(responseJson);
            }else{

                console.log('error');
            }
        })
        .catch((error)=>{
            console.log(error);
        })
       
    }

    onChange = e => {
        //console.log(e.target.name, e.target.value)

        this.setState({
            [e.target.name] : e.target.value
        })
    }

    render(){ 
        // if (this.state.redirectToReferrer) {
        //     return (<Redirect to={'/inicio'}/>)
        // }

        // if(sessionStorage.getItem('token')){
        //     return (<Redirect to={'/'}/>)
        // }

        if (localStorage.getItem("token")) {
            return <Redirect to="/" />;
          }
             
        return (
            <div className="hold-transition login-page" >
                <div className="login-box">
                    <div className="login-logo">
                        <b>WWF </b>Chile
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Bienvenido</p>
                            <div className="input-group mb-3">
                                <input  name="email" className="form-control" placeholder="Email" onChange={this.onChange} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" name="clave" className="form-control" placeholder="Clave" onChange={this.onChange} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button  className="btn btn-primary btn-block" onClick={this.Login}>Iniciar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}