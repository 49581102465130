import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Errores() {
    const baseUrl='https://websfym.wwf.cl/api/dona/error.php';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [errordonaSeleccionado, setErrordonaSeleccionado]=useState({         
        id: '',
        nombre:'',
        titulo_p: '',
        subtitulo_p: '',
        imagen_p: '',
        boton1: '',
        boton2: '',
        boton3: '',
        boton1_text: '',
        boton2_text: '',
        boton3_text: '',
        estatus: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setErrordonaSeleccionado({
            ...errordonaSeleccionado,
            [name]:value
        })
    }

    const [texto2, setValue2] = useState('');

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setErrordonaSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        //console.log(estatuslist)
    }

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setErrordonaSeleccionado({
            ...errordonaSeleccionado,
            [name]: files[0],
        })
        //console.log(errordonaSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }
    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }
    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        console.log(response);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", errordonaSeleccionado.nombre);
        f.append("titulo_p", errordonaSeleccionado.titulo_p);
        // f.append("subtitulo_p", errordonaSeleccionado.subtitulo_p,);
        f.append("subtitulo_p", texto2);
        f.append("imagen_p", errordonaSeleccionado.imagen_p,);
        f.append("boton1", errordonaSeleccionado.boton1);
        f.append("boton2", errordonaSeleccionado.boton2);
        f.append("boton3", errordonaSeleccionado.boton3);
        f.append("boton1_text", errordonaSeleccionado.boton1_text);
        f.append("boton2_text", errordonaSeleccionado.boton2_text);
        f.append("boton3_text", errordonaSeleccionado.boton3_text);
        f.append("METHOD", "POST");
        //console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
            setData(data.concat(response.data));
            peticionGet();
            abrirCerrarModalInsertar();
        }).catch(error=>{
            console.log(error);
        })
        setErrordonaSeleccionado({
            id: '',
            nombre:'',
            titulo_p: '',
            subtitulo_p: '',
            imagen_p: '',
            boton1: '',
            boton2: '',
            boton3: '',
            boton1_text: '',
            boton2_text: '',
            boton3_text: ''

        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", errordonaSeleccionado.nombre);
        f.append("titulo_p", errordonaSeleccionado.titulo_p);
        f.append("subtitulo_p", errordonaSeleccionado.subtitulo_p || texto2);
        f.append("imagen_p", errordonaSeleccionado.imagen_p,);
        f.append("boton1", errordonaSeleccionado.boton1);
        f.append("boton2", errordonaSeleccionado.boton2);
        f.append("boton3", errordonaSeleccionado.boton3);
        f.append("boton1_text", errordonaSeleccionado.boton1_text);
        f.append("boton2_text", errordonaSeleccionado.boton2_text);
        f.append("boton3_text", errordonaSeleccionado.boton3_text);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: errordonaSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(error=>{
            if(error.id===errordonaSeleccionado.id){
                error.nombre=errordonaSeleccionado.nombre;
                error.titulo_p=errordonaSeleccionado.titulo_p;
                error.subtitulo_p=errordonaSeleccionado.subtitulo_p;
                error.imagen_p=errordonaSeleccionado.imagen_p;
                error.boton1=errordonaSeleccionado.boton1;
                error.boton2=errordonaSeleccionado.boton2;
                error.boton3=errordonaSeleccionado.boton3;
            }
        });
        //setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: errordonaSeleccionado.id}})
        .then(response=>{
        setData(data.filter(error=>error.id!==errordonaSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setErrordonaSeleccionado({
                ...errordonaSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: errordonaSeleccionado.id,nombre: errordonaSeleccionado.nombre}})
        .then(response=>{
        setData(data.filter(error=>error.id!==errordonaSeleccionado.id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const seleccionarErrordona=(error, caso)=>{
        setErrordonaSeleccionado(error);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const verCampana=(error, nombre)=>{        
        window.open('https://dona.wwf.cl/error.php?camp='+nombre, '_blank')
    }

    useEffect(()=>{
        setTimeout(() => peticionGet(), 500);
    },[])

    const modules = {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: ["small", false, "large", "huge"] }, { color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
              { align: [] }
            ],
            ["link", "image", "video"],
            ["clean"]
          ]
        },
        clipboard: { matchVisual: false }
      };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
    ];

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Paginas de error Dona</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Dona/Error</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Titulo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(error=>(
                                                <tr key={error.id}>
                                                    <td>{error.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/dona/upload/'+error.imagen_p} alt="Imagen" className="brand-image " style={{width: '100px'}} /></td>
                                                    <td>{error.nombre}</td>
                                                    <td>{error.titulo_p}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={error.estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${error.id}`} name={`estatus-${error.id}`} data-id={error.id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${error.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarErrordona(error, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarErrordona(error, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarErrordona(error, "Eliminar")}>Eliminar</button>{"  "}
                                                        <button className="btn btn-info" onClick={()=>verCampana(error, error.nombre)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                {/* <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange}/> */}
                                                <ReactQuill theme="snow" value={texto2} modules={modules} formats={formats} onChange={setValue2}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{errordonaSeleccionado.imagen_p.name ? errordonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <br />
                                                <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                            </div>
                                            <span>Boton 1</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <span>Boton 2</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Boton 3</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton3"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton3_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.nombre}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input type="text" className="form-control" name="titulo_p" onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.titulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                               
                                                <ReactQuill theme="snow" value={texto2 || errordonaSeleccionado.subtitulo_p} modules={modules} formats={formats} onChange={setValue2} />
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" value={errordonaSeleccionado.imagen_p==='' ? errordonaSeleccionado.imagen_p.name: ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{errordonaSeleccionado.imagen_p.name ? errordonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_p">{typeof errordonaSeleccionado.imagen_p==='string' ? errordonaSeleccionado.imagen_p : ""}</label> 
                                                <br />
                                                <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                            </div>
                                           
                                            <span>Boton 1</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.boton1}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.boton1_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 2</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.boton2}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.boton2_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 3</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton3"  onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.boton3}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton3_text"  onChange={handleChange} value={errordonaSeleccionado && errordonaSeleccionado.boton3_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {errordonaSeleccionado && errordonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas <b>duplicar</b>  {errordonaSeleccionado && errordonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Errores;