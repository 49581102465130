import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';

export default class Menu extends Component {
    render() {
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            
            <Link className="brand-link" to="/admin">
                <img src="../repositorio/img/logo.png" alt="Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />    
                <span className="brand-text font-weight-light">WWF Chile</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel (optional) 
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">Genecis</a>
                    </div>
                </div>*/}
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class
                        with font-awesome or any other icon font library 
                        <li className="nav-item has-treeview ">
                            <a href="#" className="nav-link ">
                                <p>
                                    Dona<i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link className="nav-link" to="donacampanas">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Campañas</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="donagracias">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Gracias</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="donaerror">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Error</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item has-treeview ">
                            <a href="#" className="nav-link ">
                                <p>
                                    Adopta<i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link className="nav-link" to="adoptacampanas">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Campañas</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="adoptagracias">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Gracias</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="adoptaerror">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Error</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item has-treeview ">
                            <a href="#" className="nav-link ">
                                <p>
                                    Hazte Socio<i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link className="nav-link" to="haztesociocampanas">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Campañas</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="haztesociogracias">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Gracias</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="haztesocioerror">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Error</p>
                                    </Link>
                                </li>
                            </ul>
                        </li>*/}
                        <li class="nav-header">Dona</li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/donacampanas"><i class="fas fa-circle nav-icon"></i> Campañas</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/donagracias"><i class="fas fa-circle nav-icon"></i>Gracias</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/donaerror"><i class="fas fa-circle nav-icon"></i>Error</Link>
                        </li>
                        
                        <li class="nav-header">Adopta</li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/especies"><i class="fas fa-circle nav-icon"></i> Especies</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/adoptacampanas"><i class="fas fa-circle nav-icon"></i> Campañas</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/adoptagracias"><i class="fas fa-circle nav-icon"></i>Gracias</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/adoptaerror"><i class="fas fa-circle nav-icon"></i>Error</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/preguntas"><i class="fas fa-circle nav-icon"></i>Preguntas</Link>
                        </li>
                        <li class="nav-header">Hazte socio</li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/haztesociocampanas"><i class="fas fa-circle nav-icon"></i> Campañas</Link>
                        </li>
                        <li className="nav-item">
                            {/* <Link className="nav-link" to="/haztesociogracias"><i class="fas fa-circle nav-icon"></i>Gracias</Link> */}
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/haztesocioerror"><i class="fas fa-circle nav-icon"></i>Error</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/agencias"><i class="fas fa-circle nav-icon"></i>Agencias</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/captadores"><i class="fas fa-circle nav-icon"></i>Captadores</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/telemarketing"><i class="fas fa-circle nav-icon"></i>Telemarketing</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/socios">Socios</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" to="/formularios">Formularios</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" to="/usuarios">Usuarios</Link>
                        </li>
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
        )
    }
}
