import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Errores() {
    const baseUrl='https://websfym.wwf.cl/api/haztesocio/error.php';
    const [data, setData]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [errorhaztesocioSeleccionado, setErrorhaztesocioSeleccionado]=useState({         
        id: '',
        nombre:'',
        titulo_p: '',
        subtitulo_p: '',
        imagen_p: '',
        boton1: '',
        boton2: '',
        boton3: '',
        boton1_text: '',
        boton2_text: '',
        boton3_text: ''
    });

    const handleChange=e=>{
        const {name, value}=e.target;
        setErrorhaztesocioSeleccionado({
            ...errorhaztesocioSeleccionado,
            [name]:value
        })
        //console.log(errorhaztesocioSeleccionado);
    }

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setErrorhaztesocioSeleccionado({
            ...errorhaztesocioSeleccionado,
            [name]: files[0],
        })
        //console.log(errorhaztesocioSeleccionado);
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }

    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }
    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        // console.log(response);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", errorhaztesocioSeleccionado.nombre);
        f.append("titulo_p", errorhaztesocioSeleccionado.titulo_p);
        f.append("subtitulo_p", errorhaztesocioSeleccionado.subtitulo_p,);
        f.append("imagen_p", errorhaztesocioSeleccionado.imagen_p,);
        f.append("boton1", errorhaztesocioSeleccionado.boton1);
        f.append("boton2", errorhaztesocioSeleccionado.boton2);
        f.append("boton3", errorhaztesocioSeleccionado.boton3);
        f.append("boton1_text", errorhaztesocioSeleccionado.boton1_text);
        f.append("boton2_text", errorhaztesocioSeleccionado.boton2_text);
        f.append("boton3_text", errorhaztesocioSeleccionado.boton3_text);
        
        f.append("METHOD", "POST");
        // console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            // console.log(response);
        setData(data.concat(response.data));
        peticionGet();
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
        setErrorhaztesocioSeleccionado({
            id: '',
            nombre:'',
            titulo_p: '',
            subtitulo_p: '',
            imagen_p: '',
            boton1: '',
            boton2: '',
            boton3: '',
            boton1_text: '',
            boton2_text: '',
            boton3_text: ''
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", errorhaztesocioSeleccionado.nombre);
        f.append("titulo_p", errorhaztesocioSeleccionado.titulo_p);
        f.append("subtitulo_p", errorhaztesocioSeleccionado.subtitulo_p,);
        f.append("imagen_p", errorhaztesocioSeleccionado.imagen_p,);
        f.append("boton1", errorhaztesocioSeleccionado.boton1);
        f.append("boton2", errorhaztesocioSeleccionado.boton2);
        f.append("boton3", errorhaztesocioSeleccionado.boton3);
        f.append("boton1_text", errorhaztesocioSeleccionado.boton1_text);
        f.append("boton2_text", errorhaztesocioSeleccionado.boton2_text);
        f.append("boton3_text", errorhaztesocioSeleccionado.boton3_text);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: errorhaztesocioSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(error=>{
            if(error.id===errorhaztesocioSeleccionado.id){
                error.nombre=errorhaztesocioSeleccionado.nombre;
                error.titulo_p=errorhaztesocioSeleccionado.titulo_p;
                error.subtitulo_p=errorhaztesocioSeleccionado.subtitulo_p;
                error.imagen_p=errorhaztesocioSeleccionado.imagen_p;
                error.boton1=errorhaztesocioSeleccionado.boton1;
                error.boton2=errorhaztesocioSeleccionado.boton2;
                error.boton3=errorhaztesocioSeleccionado.boton3;
            }
        });
        //setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: errorhaztesocioSeleccionado.id}})
        .then(response=>{
        setData(data.filter(error=>error.id!==errorhaztesocioSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: errorhaztesocioSeleccionado.id,nombre: errorhaztesocioSeleccionado.nombre}})
        .then(response=>{
        setData(data.filter(error=>error.id!==errorhaztesocioSeleccionado.id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }
    const seleccionarErrorhaztesocio=(error, caso)=>{
        setErrorhaztesocioSeleccionado(error);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const verCampana=(error, nombre)=>{        
        window.open('https://hsocio.wwf.cl/error.php?camp='+nombre, '_blank')
    }

    useEffect(()=>{
        peticionGet();
    },[])

    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Paginas de error Hazte socio</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Hazte socio/Error</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Titulo</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(error=>(
                                                <tr key={error.id}>
                                                    <td>{error.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/haztesocio/upload/'+error.imagen_p} alt="Imagen" className="brand-image " style={{width: '100px'}} /></td>
                                                    <td>{error.nombre}</td>
                                                    <td>{error.titulo_p}</td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarErrorhaztesocio(error, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarErrorhaztesocio(error, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarErrorhaztesocio(error, "Eliminar")}>Eliminar</button> {"  "}
                                                        <button className="btn btn-info" onClick={()=>verCampana(error, error.nombre)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{errorhaztesocioSeleccionado.imagen_p.name ? errorhaztesocioSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                    <span>2000px - 800px peso max:5000MB</span>
                                                </div>
                                            </div>
                                            <span>Boton 1</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                            <span>Boton 2</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Boton 3</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton3"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton3_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.nombre}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.titulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.subtitulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{errorhaztesocioSeleccionado.imagen_p.name ? errorhaztesocioSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_p">{typeof errorhaztesocioSeleccionado.imagen_p==='string' ? errorhaztesocioSeleccionado.imagen_p : ""}</label> 
                                                <br/>
                                                <span>2000px - 800px peso max:5000MB</span>
                                            </div>
                                            <span>Boton 1</span>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.boton1}/>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.boton1_text}/>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                            <span>Boton 2</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.boton2}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.boton2_text}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Boton 3</span>
                                            <div className="row">                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton3"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.boton3}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton3_text"  onChange={handleChange} value={errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.boton3_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {errorhaztesocioSeleccionado && errorhaztesocioSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Errores;