import React, {useState, useEffect} from 'react';
import { BrowserRouter as  Link} from 'react-router-dom';
import {Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


function Campanas() {
    const baseUrl='https://websfym.wwf.cl/api/haztesocio/';
    
    const [data, setData]=useState([]);
    const [dataform, setDataform]=useState([]);
    const [modalInsertar, setModalInsertar]= useState(false);
    const [modalEditar, setModalEditar]= useState(false);
    const [modalEliminar, setModalEliminar]= useState(false);
    const [modalDuplicar, setModalDuplicar]= useState(false);
    const [campanadonaSeleccionado, setCampanadonaSeleccionado]=useState({         
        id: '',
        nombre:'',
        titulo_p: '',
        subtitulo_p: '',
        imagen_p: '',
        media_p: '',
        titulo_s2: '',
        texto_s2: '',
        imagen_s2: '',
        color_s2: '',
        titulo_s3: '',
        texto_s3: '',
        imagen_s3: '',
        color_s3: '',
        titulo_s4: '',
        texto_s4: '',
        imagen_s4: '',
        color_s4: '',
        titulo_s5: '',
        texto_s5: '',
        imagen_s5: '',
        color_s5: '',
        titulo_s6: '',
        texto_s6: '',
        imagen_s6: '',
        color_s6: '',
        boton1: '',
        boton2: '',
        tipo_landing:'',
        formulario_id: '',
        boton1_text:'',
        boton2_text:'',
        estatus: '',
        html:''
    });   

    

    const handleChange=e=>{
        const {name, value}=e.target;
        setCampanadonaSeleccionado({
            ...campanadonaSeleccionado,
            [name]:value
        })
        console.log(campanadonaSeleccionado);
    }

    const [texto2, setValue2] = useState('');
    const [texto3, setValue3] = useState('');
    const [texto4, setValue4] = useState('');
    const [texto5, setValue5] = useState('');
    const [texto6, setValue6] = useState('');
    

    const handleChangeImage=e=>{
        const {name,files}=e.target;
        setCampanadonaSeleccionado({
            ...campanadonaSeleccionado,
            [name]: files[0],
        })
        console.log(campanadonaSeleccionado);
    }

    const handleCheck=e=>{
        const {name, checked}=e.target;
        const estatuslist=e.target.dataset.id;
        setCampanadonaSeleccionado((prevState)=>({
        ...prevState,
        [name]: checked
        }))
        peticionPutEstatus(estatuslist,checked);
        console.log(estatuslist)
    }

    const abrirCerrarModalInsertar=()=>{
        setModalInsertar(!modalInsertar);
    }

    const abrirCerrarModalEditar=()=>{
        setModalEditar(!modalEditar);
    }

    const abrirCerrarModalEliminar=()=>{
        setModalEliminar(!modalEliminar);
    }
    const abrirCerrarModalDuplicar=()=>{
        setModalDuplicar(!modalDuplicar);
    }
    const peticionGet=async()=>{
        await axios.get(baseUrl)
        .then(response=>{
        setData(response.data);
        console.log(response);
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionPost=async()=>{
        var f = new FormData();
        f.append("nombre", campanadonaSeleccionado.nombre);
        f.append("titulo_p", campanadonaSeleccionado.titulo_p);
        f.append("subtitulo_p", campanadonaSeleccionado.subtitulo_p,);
        f.append("imagen_p", campanadonaSeleccionado.imagen_p,);
        f.append("media_p", campanadonaSeleccionado.media_p);

        f.append("titulo_s2", campanadonaSeleccionado.titulo_s2);
        // f.append("texto_s2", campanadonaSeleccionado.texto_s2);
        f.append("texto_s2", texto2);
        f.append("imagen_s2", campanadonaSeleccionado.imagen_s2);
        f.append("color_s2", campanadonaSeleccionado.color_s2);

        f.append("titulo_s3", campanadonaSeleccionado.titulo_s3);
        // f.append("texto_s3", campanadonaSeleccionado.texto_s3);
        f.append("texto_s3", texto3);
        f.append("imagen_s3", campanadonaSeleccionado.imagen_s3);
        f.append("color_s3", campanadonaSeleccionado.color_s3);

        f.append("titulo_s4", campanadonaSeleccionado.titulo_s4);
        // f.append("texto_s4", campanadonaSeleccionado.texto_s4);
        f.append("texto_s4", texto4);
        f.append("imagen_s4", campanadonaSeleccionado.imagen_s4);
        f.append("color_s4", campanadonaSeleccionado.color_s4);

        f.append("titulo_s5", campanadonaSeleccionado.titulo_s5);
        // f.append("texto_s5", campanadonaSeleccionado.texto_s5);
        f.append("texto_s5", texto5);
        f.append("imagen_s5", campanadonaSeleccionado.imagen_s5);
        f.append("color_s5", campanadonaSeleccionado.color_s5);

        f.append("titulo_s6", campanadonaSeleccionado.titulo_s6);
        // f.append("texto_s6", campanadonaSeleccionado.texto_s6);
        f.append("texto_s6", texto6);
        f.append("imagen_s6", campanadonaSeleccionado.imagen_s6);
        f.append("color_s6", campanadonaSeleccionado.color_s6);

        f.append("boton1", campanadonaSeleccionado.boton1);
        f.append("boton2", campanadonaSeleccionado.boton2);
        f.append("boton1_text", campanadonaSeleccionado.boton1_text);
        f.append("boton2_text", campanadonaSeleccionado.boton2_text);
                
        f.append("formulario_id", campanadonaSeleccionado.formulario_id);
        f.append("html", campanadonaSeleccionado.html);
        f.append("METHOD", "POST");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
        setData(data.concat(response.data));
        peticionGet();
        abrirCerrarModalInsertar();
        }).catch(error=>{
        console.log(error);
        })
        setCampanadonaSeleccionado({
            id: '',
            nombre:'',
            titulo_p: '',
            subtitulo_p: '',
            imagen_p: '',
            media_p: '',
            titulo_s2: '',
            texto_s2: '',
            imagen_s2: '',
            color_s2: '',
            titulo_s3: '',
            texto_s3: '',
            imagen_s3: '',
            color_s3: '',
            titulo_s4: '',
            texto_s4: '',
            imagen_s4: '',
            color_s4: '',
            titulo_s5: '',
            texto_s5: '',
            imagen_s5: '',
            color_s5: '',
            titulo_s6: '',
            texto_s6: '',
            imagen_s6: '',
            color_s6: '',
            boton1: '',
            boton2: '',
            tipo_landing:'',
            formulario_id: ''
        })
    }

    const peticionPut=async()=>{
        var f = new FormData();
        f.append("nombre", campanadonaSeleccionado.nombre);
        f.append("titulo_p", campanadonaSeleccionado.titulo_p);
        f.append("subtitulo_p", campanadonaSeleccionado.subtitulo_p,);
        f.append("imagen_p", campanadonaSeleccionado.imagen_p,);
        f.append("media_p", campanadonaSeleccionado.media_p,);
        
        f.append("titulo_s2", campanadonaSeleccionado.titulo_s2);
        f.append("texto_s2", campanadonaSeleccionado.texto_s2 || texto2);
        f.append("imagen_s2", campanadonaSeleccionado.imagen_s2);
        f.append("color_s2", campanadonaSeleccionado.color_s2);

        f.append("titulo_s3", campanadonaSeleccionado.titulo_s3);
        f.append("texto_s3", campanadonaSeleccionado.texto_s3 || texto3,);
        f.append("imagen_s3", campanadonaSeleccionado.imagen_s3);
        f.append("color_s3", campanadonaSeleccionado.color_s3);

        f.append("titulo_s4", campanadonaSeleccionado.titulo_s4);
        f.append("texto_s4", campanadonaSeleccionado.texto_s4 || texto4,);
        f.append("imagen_s4", campanadonaSeleccionado.imagen_s4);
        f.append("color_s4", campanadonaSeleccionado.color_s4);

        f.append("titulo_s5", campanadonaSeleccionado.titulo_s5);
        f.append("texto_s5", campanadonaSeleccionado.texto_s5 || texto5,);
        f.append("imagen_s5", campanadonaSeleccionado.imagen_s5);
        f.append("color_s5", campanadonaSeleccionado.color_s5);

        f.append("titulo_s6", campanadonaSeleccionado.titulo_s6);
        f.append("texto_s6", campanadonaSeleccionado.texto_s6 || texto6,);
        f.append("imagen_s6", campanadonaSeleccionado.imagen_s6);
        f.append("color_s6", campanadonaSeleccionado.color_s6);
        f.append("boton1", campanadonaSeleccionado.boton1);
        f.append("boton2", campanadonaSeleccionado.boton2);
        f.append("boton1_text", campanadonaSeleccionado.boton1_text);
        f.append("boton2_text", campanadonaSeleccionado.boton2_text);
        f.append("tipo_landing", campanadonaSeleccionado.tipo_landing);
        f.append("formulario_id", campanadonaSeleccionado.formulario_id);
        f.append("html", campanadonaSeleccionado.html);
        f.append("METHOD", "PUT");
        await axios.post(baseUrl, f, {params: {id: campanadonaSeleccionado.id}})
        .then(response=>{
        var dataNueva= data;
        dataNueva.map(campana=>{
            if(campana.id===campanadonaSeleccionado.id){
                campana.nombre=campanadonaSeleccionado.nombre;
                campana.titulo_p=campanadonaSeleccionado.titulo_p;
                campana.subtitulo_p=campanadonaSeleccionado.subtitulo_p;
                campana.imagen_p=campanadonaSeleccionado.imagen_p;
                campana.media_p=campanadonaSeleccionado.media_p;
          
                campana.titulo_s2=campanadonaSeleccionado.titulo_s2;
                campana.texto_s2=campanadonaSeleccionado.texto_s2;
                campana.imagen_s2=campanadonaSeleccionado.imagen_s2;
                campana.color_s2=campanadonaSeleccionado.color_s2;

                campana.titulo_s3=campanadonaSeleccionado.titulo_s3;
                campana.texto_s3=campanadonaSeleccionado.texto_s3;
                campana.imagen_s3=campanadonaSeleccionado.imagen_s3;
                campana.color_s3=campanadonaSeleccionado.color_s3;

                campana.titulo_s4=campanadonaSeleccionado.titulo_s4;
                campana.texto_s4=campanadonaSeleccionado.texto_s4;
                campana.imagen_s4=campanadonaSeleccionado.imagen_s4;
                campana.color_s4=campanadonaSeleccionado.color_s4;

                campana.titulo_s5=campanadonaSeleccionado.titulo_s5;
                campana.texto_s5=campanadonaSeleccionado.texto_s5;
                campana.imagen_s5=campanadonaSeleccionado.imagen_s5;
                campana.color_s5=campanadonaSeleccionado.color_s5;

                campana.titulo_s6=campanadonaSeleccionado.titulo_s6;
                campana.texto_s6=campanadonaSeleccionado.texto_s6;
                campana.imagen_s6=campanadonaSeleccionado.imagen_s6;
                campana.color_s6=campanadonaSeleccionado.color_s6;

                campana.boton1=campanadonaSeleccionado.boton1;
                campana.boton2=campanadonaSeleccionado.boton2;
                

                campana.tipo_landing=campanadonaSeleccionado.tipo_landing;
                
                campana.formulario_id=campanadonaSeleccionado.formulario_id;
            }
        });
        //setData(dataNueva);
        peticionGet();
        abrirCerrarModalEditar();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionDelete=async()=>{
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseUrl, f, {params: {id: campanadonaSeleccionado.id}})
        .then(response=>{
        setData(data.filter(campana=>campana.id!==campanadonaSeleccionado.id));
        peticionGet();
        abrirCerrarModalEliminar();
        }).catch(error=>{
        console.log(error);
        })
    }
    const peticionDuplicar=async()=>{
        var f = new FormData();
        f.append("METHOD", "DUPLICAR");
        await axios.post(baseUrl, f, {params: {id: campanadonaSeleccionado.id,nombre: campanadonaSeleccionado.nombre}})
        .then(response=>{
        setData(data.filter(campana=>campana.id!==campanadonaSeleccionado.id));
        peticionGet();
        abrirCerrarModalDuplicar();
        }).catch(error=>{
        console.log(error);
        })
    }
    const peticionPutEstatus=async(id,check)=>{
        var f = new FormData();
        f.append("id", id);
        f.append("estatus", check);
        f.append("METHOD", "PUTESTATUS");
        console.log(f);
        await axios.post(baseUrl, f, {params: {id,check}})
        .then(response=>{
            var dataNueva= data;
            const a=`estatus-${id}`
            setCampanadonaSeleccionado({
                ...campanadonaSeleccionado,
                [a]:check
            })
            peticionGet();
        }).catch(error=>{
        console.log(error);
        })
    }

    const peticionGetFormularios=async()=>{
        var f = new FormData();
        f.append("METHOD", "GETFORMULARIOS");
        console.log(f);
        await axios.post(baseUrl, f)
        .then(response=>{
            console.log(response);
            setDataform(response.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    const seleccionarCampanadona=(campana, caso)=>{
        setCampanadonaSeleccionado(campana);
        if(caso==="Editar"){
            abrirCerrarModalEditar()
        }else if(caso==="Duplicar"){
            abrirCerrarModalDuplicar()
        }else{
            abrirCerrarModalEliminar()
        }
        // (caso==="Editar")?
        // abrirCerrarModalEditar():
        // abrirCerrarModalEliminar()
    }

    const verCampana=(campana, nombre)=>{        
        window.open('https://hsocio.wwf.cl/?camp='+nombre, '_blank')
    }

    useEffect(()=>{
        peticionGet();
        peticionGetFormularios();
    },[])


    const modules = {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: ["small", false, "large", "huge"] }, { color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
              { align: [] }
            ],
            ["link", "image", "video"],
            ["clean"]
          ]
        },
        clipboard: { matchVisual: false }
      };

      const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
      ];

      
    return(
        <div> 
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Campañas</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/inicio">Inicio</Link></li>
                        <li className="breadcrumb-item active">Hazte Socio/Campañas</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <button type="button" className="btn btn-default" onClick={()=>abrirCerrarModalInsertar()}>Insertar</button>
                                    </div>
                                </div>
                                {/* /.lista*/}
                                <div className="card-body">
                                    <table id="usuarios" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Imagen</th>
                                                <th>Nombre</th>
                                                <th>Titulo</th>
                                                <th>Estatus</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map(campana=>(
                                                <tr key={campana.id}>
                                                    <td>{campana.id}</td>
                                                    <td><img src={'https://websfym.wwf.cl/api/haztesocio/upload/'+campana.imagen_p} alt="Imagen" className="brand-image " style={{width: '100px'}} /></td>
                                                    <td>{campana.nombre}</td>
                                                    <td>{campana.titulo_p}</td>
                                                    <td>
                                                        <div className="custom-control custom-switch">
                                                            <input type="checkbox" checked={campana.estatus==='true' ? true : false} className="custom-control-input" id={`estatus-${campana.id}`} name={`estatus-${campana.id}`} data-id={campana.id} onChange={handleCheck} />
                                                            <label className="custom-control-label"  htmlFor={`estatus-${campana.id}`}></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-warning" onClick={()=>seleccionarCampanadona(campana, "Editar")}>Editar</button> {"  "}
                                                        <button className="btn btn-success" onClick={() => seleccionarCampanadona(campana, "Duplicar")}>Duplicar</button> {"  "}
                                                        <button className="btn btn-danger" onClick={()=>seleccionarCampanadona(campana, "Eliminar")}>Eliminar</button> {"  "}
                                                        <button className="btn btn-info" onClick={()=>verCampana(campana, campana.nombre)}>Ver</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <Modal isOpen={modalInsertar}>
                                        <ModalHeader>Insertar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input className="form-control" type="text" name="nombre"  onChange={handleChange}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{campanadonaSeleccionado.imagen_p.name ? campanadonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                    <br />
                                                    <span>Sugerido: 2000px - 800px peso max:5000MB</span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Media principal</label>
                                                <input className="form-control" type="text" name="media_p"  onChange={handleChange}/>
                                                <span>URL de compartir/ incorporar YOUTUBE</span>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label>Formulario</label>
                                                <select className="form-control select2 select2-hidden-accessible" name="formulario_id" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    <option value='0'>Selecciona Formulario</option>
                                                    {dataform?.map(formulario => (
                                                        <option key={formulario.form_id} value={formulario.form_id}>{formulario.form_nombre}</option>
                                                    ))}
                                                </select>
                                                <span>**Previamente creado en modulo Formularios</span>
                                            </div>



                                            <span>Sección 2</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s2"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto.</label>
                                                        {/* <input className="form-control" type="text" name="texto_s2"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto2} modules={modules} formats={formats} onChange={setValue2}/>
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s2" id="imagen_s2" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s2">{campanadonaSeleccionado.imagen_s2.name ? campanadonaSeleccionado.imagen_s2.name : "Cargar Imagen"}</label>
                                                        <span>2000px - 800px peso max:5000MB</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s2"  onChange={handleChange}/>                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Sección 3</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s3"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s3"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto3} modules={modules} formats={formats} onChange={setValue3}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s3" id="imagen_s3" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s3">{campanadonaSeleccionado.imagen_s3.name ? campanadonaSeleccionado.imagen_s3.name : "Cargar Imagen"}</label>
                                                        <span>2000px - 800px peso max:5000MB</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s3"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Sección 4</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s4"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s4"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto4} modules={modules} formats={formats} onChange={setValue4}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s4" id="imagen_s4" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s4">{campanadonaSeleccionado.imagen_s4.name ? campanadonaSeleccionado.imagen_s4.name : "Cargar Imagen"}</label>
                                                        <span>2000px - 800px peso max:5000MB</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s4"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Sección 5</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s5"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s5"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto5} modules={modules} formats={formats} onChange={setValue5}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s5" id="imagen_s5" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s5">{campanadonaSeleccionado.imagen_s5.name ? campanadonaSeleccionado.imagen_s5.name : "Cargar Imagen"}</label>
                                                        <span>2000px - 800px peso max:5000MB</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s5"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Sección 6</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s6"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s6"  onChange={handleChange}/> */}
                                                        {/* <ReactQuill theme="snow" value={texto6}  onChange={setValue1}/> */}
                                                        <ReactQuill theme="snow" value={texto6} modules={modules} formats={formats} onChange={setValue6}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s6" id="imagen_s6" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s6">{campanadonaSeleccionado.imagen_s6.name ? campanadonaSeleccionado.imagen_s6.name : "Cargar Imagen"}</label>
                                                        <span>2000px - 800px peso max:5000MB</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color" name="color_s6"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 1</span>
                                            <div className="row">
                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 2</span>
                                            <div className="row">
                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>HTML Correo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html">{campanadonaSeleccionado.html.name ? campanadonaSeleccionado.html.name : "Cargar HTML"}</label>
                                                </div>
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPost()}>Insertar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEditar}>
                                        <ModalHeader>Editar</ModalHeader>
                                        <ModalBody>
                                            <div className="form-group">
                                                <label>Nombre</label>
                                                <input type="text" className="form-control" name="nombre" onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.nombre}/>
                                                <span>**Sin espacios. Representa nombre en URL</span>
                                            </div>
                                            <div className="form-group">
                                                <label>Titulo principal</label>
                                                <input className="form-control" type="text" name="titulo_p"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Subtitulo principal</label>
                                                <input className="form-control" type="text" name="subtitulo_p"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.subtitulo_p}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Imagen principal</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="imagen_p" id="imagen_p" onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="imagen_p">{campanadonaSeleccionado.imagen_p.name ? campanadonaSeleccionado.imagen_p.name : "Cargar Imagen"}</label>
                                                </div>
                                                <label className="" for="imagen_p">{typeof campanadonaSeleccionado.imagen_p==='string' ? campanadonaSeleccionado.imagen_p : ""}</label> 
                                            </div>
                                            <div className="form-group">
                                                <label>Media principal</label>
                                                <input className="form-control" type="text" name="media_p"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.media_p}/>
                                            </div>
                                           
                                            <div className="form-group">
                                                <label>Formulario</label>
                                                <select  className="form-control select2 select2-hidden-accessible" name="formulario_id" data-select2-id="1" tabindex="-1" aria-hidden="true" onChange={handleChange}>
                                                    {dataform?.map(formulario => (
                                                    <option key={formulario.form_id} value={formulario.form_id} select={campanadonaSeleccionado.formulario_id===formulario.form_id ? 'select' : ''}>{formulario.form_nombre}</option>
                                                ))}
                                                </select>
                                                <span>**Previamente creado en modulo Formularios</span>
                                            </div>
                                            <span>Sección 2</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s2"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_s2}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto.</label>
                                                        {/* <input className="form-control" type="text" name="texto_s2"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto2 || campanadonaSeleccionado.texto_s2} modules={modules} formats={formats} onChange={setValue2} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s2" id="imagen_s2" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s2">{campanadonaSeleccionado.imagen_s2.name ? campanadonaSeleccionado.imagen_s2.name : "Cargar Imagen"}</label>
                                                    </div>
                                                    <label className="" for="imagen_p">{typeof campanadonaSeleccionado.imagen_s2==='string' ? campanadonaSeleccionado.imagen_s2 : ""}</label> 
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s2"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.color_s2}/>                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Sección 3</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s3"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_s3}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s3"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto3 || campanadonaSeleccionado.texto_s3} modules={modules} formats={formats} onChange={setValue3} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s3" id="imagen_s3" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s3">{campanadonaSeleccionado.imagen_s3.name ? campanadonaSeleccionado.imagen_s3.name : "Cargar Imagen"}</label>
                                                    </div>
                                                    <label className="" for="imagen_p">{typeof campanadonaSeleccionado.imagen_s3==='string' ? campanadonaSeleccionado.imagen_s3 : ""}</label> 
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s3"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.color_s3}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Sección 4</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s4"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_s4}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s4"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto4 || campanadonaSeleccionado.texto_s4} modules={modules} formats={formats} onChange={setValue4} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s4" id="imagen_s4" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s4">{campanadonaSeleccionado.imagen_s4.name ? campanadonaSeleccionado.imagen_s4.name : "Cargar Imagen"}</label>
                                                    </div>
                                                    <label className="" for="imagen_s4">{typeof campanadonaSeleccionado.imagen_s4==='string' ? campanadonaSeleccionado.imagen_s4 : ""}</label> 
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s4"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.color_s4}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Sección 5</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s5"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_s5}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s5"  onChange={handleChange}/> */}
                                                        <ReactQuill theme="snow" value={texto5 || campanadonaSeleccionado.texto_s5} modules={modules} formats={formats} onChange={setValue5}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s5" id="imagen_s5" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s5">{campanadonaSeleccionado.imagen_s5.name ? campanadonaSeleccionado.imagen_s5.name : "Cargar Imagen"}</label>
                                                    </div>
                                                    <label className="" for="imagen_s5">{typeof campanadonaSeleccionado.imagen_s5==='string' ? campanadonaSeleccionado.imagen_s5 : ""}</label> 
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color"  name="color_s5"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.color_s5}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Sección 6</span>

                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Titulo</label>
                                                        <input className="form-control" type="text" name="titulo_s6"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.titulo_s6}/>
                                                    </div>
                                                </div>
                                                <div className="col-12">    
                                                    <div className="form-group">
                                                        <label>Texto</label>
                                                        {/* <input className="form-control" type="text" name="texto_s6"  onChange={handleChange}/> */}
                                                        {/* <ReactQuill theme="snow" value={texto6}  onChange={setValue1}/> */}
                                                        <ReactQuill theme="snow" value={texto6 || campanadonaSeleccionado.texto_s6} modules={modules} formats={formats} onChange={setValue6}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label>Imagen</label>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="imagen_s6" id="imagen_s6" onChange={handleChangeImage} />
                                                        <label className="custom-file-label" for="imagen_s6">{campanadonaSeleccionado.imagen_s6.name ? campanadonaSeleccionado.imagen_s6.name : "Cargar Imagen"}</label>
                                                    </div>
                                                    <label className="" for="imagen_s6">{typeof campanadonaSeleccionado.imagen_s6==='string' ? campanadonaSeleccionado.imagen_s6 : ""}</label> 
                                                </div>
                                                <div className="col-6">    
                                                    <div className="form-group">
                                                        <label>Color</label>
                                                        <input className="form-control" type="color" name="color_s6"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.color_s6}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 1</span>
                                            <div className="row">
                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton1"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.boton1}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton1_text"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.boton1_text}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span>Boton 2</span>
                                            <div className="row">
                                            
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>URL</label>
                                                        <input className="form-control" type="text" name="boton2"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.boton2}/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nombre</label>
                                                        <input className="form-control" type="text" name="boton2_text"  onChange={handleChange} value={campanadonaSeleccionado && campanadonaSeleccionado.boton2_text}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>HTML Correo</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" name="html" id="html" value={campanadonaSeleccionado.html === '' ? campanadonaSeleccionado.html.name : ''} onChange={handleChangeImage} />
                                                    <label className="custom-file-label" for="html">{campanadonaSeleccionado.html.name ? campanadonaSeleccionado.html.name : "Cargar HTML"}</label>
                                                </div>
                                                <label className="" for="html">{typeof campanadonaSeleccionado.html === 'string' ? campanadonaSeleccionado.html : ""}</label>
                                                <br />
                                                <span>**Extencion de archivo debe ser HTML</span>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-primary" onClick={()=>peticionPut()}>Editar</button>{"   "}
                                            <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}>Cancelar</button>
                                        </ModalFooter>
                                    </Modal>

                                    <Modal isOpen={modalEliminar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas eliminar {campanadonaSeleccionado && campanadonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={()=>peticionDelete()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={()=>abrirCerrarModalEliminar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={modalDuplicar}>
                                        <ModalBody>
                                            ¿Estás seguro que deseas duplicar {campanadonaSeleccionado && campanadonaSeleccionado.nombre}?
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn btn-danger" onClick={() => peticionDuplicar()}>
                                                Sí
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => abrirCerrarModalDuplicar()}>
                                                No
                                            </button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Campanas;